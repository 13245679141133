import React, { useEffect, useState } from "react";
import { /*Button,*/ Input } from "reactstrap";

// some plugins
import Select from "react-select";

// some config variables
import downloadApi from "api/downloadApi";
import { downloadFile } from "lib/helpers";

import PageDescription from "components/PageDescription";
import CustomTable from "components/CustomTable";
import EmptyList from "components/EmptyList";

import useFetchList from "hooks/useFetchList";

// import { Api } from "api";
import { requestsUrl } from "../../api/endpoints";
import * as tableConfig from "./tableConfig";

import "assets/css/listviews.css";

export default function RequestsList(props) {
  const [fetchParams, setFecthParams] = useState({ skip: 0, top: 10 });
  const [apiEndpoint, setEndpoint] = useState(requestsUrl(""));
  const { data, count, fetching } = useFetchList(apiEndpoint);

  useEffect(() => {
    const filter = fetchParams.statut
      ? JSON.stringify({ statut: fetchParams.statut })
      : "";
    const urlQuery = `$skip=${fetchParams.skip}&$top=${fetchParams.top}${
      fetchParams.q ? "&$q=" + fetchParams.q : ""
    }${filter ? "&$filter=" + filter : ""}`;
    setEndpoint(requestsUrl(urlQuery));
  }, [fetchParams]);

  const exportCsv = async () => {
    try {
      const url = await downloadApi("/admin/requests/excel");
      downloadFile(url, "demandes.xlsx");
    } catch (e) {}
  };

  return (
    <div className="app-main">
      <PageDescription title="Liste des demandes" />
      <div className="list-top-view">
        <span className="text-bold export-btn" tag="button" onClick={exportCsv}>
          Exporter en CSV
        </span>
      </div>
      <div className="filters-view societe-list">
        <div />
        <div style={{ display: "flex" }}>
          <Input
            placeholder="Chercher par nom/employeur/ville"
            type="text"
            name="filter-name"
            className={"filter-input"}
            value={fetchParams.q}
            onChange={(e) => {
              e.preventDefault();
              setFecthParams({
                q: e.currentTarget.value,
                skip: 0,
                top: fetchParams.top,
              });
            }}
          ></Input>
          <Select
            placeholder="Statut"
            cacheOptions
            options={[
              {
                value: "NOT_CONFIRMED",
                label: "Devis en attente de confirmation",
              },
              {
                value: "CONFIRMED_WITHOUT_PJ",
                label: "Devis confirmé, manque PJ",
              },
              { value: "CONFIRMED_WITH_PJ", label: "Confirmé" },
            ]}
            className="react-select react-select-primary filter-input"
            classNamePrefix="react-select"
            onChange={(select) =>
              setFecthParams({ ...fetchParams, statut: select?.value })
            }
            isClearable
          />
        </div>
      </div>
      <div>
        {data.length === 0 ? (
          <EmptyList fetching={fetching} text={"Aucune demande trouvée"} />
        ) : (
          <CustomTable
            columns={tableConfig.columns}
            dataSource={data}
            cellKey={"id"}
            onRowPress={(item) => {
              props.actions.setCurrentDemand(item);
              props.history.push("/requests/details");
            }}
            pagination={{
              count,
              rowsPerPage: fetchParams.top,
              page: Math.round(fetchParams.skip / fetchParams.top),
            }}
            handleChangePage={(params) => {
              setFecthParams(params);
            }}
            handleChangeRowsPerPage={(params) => {
              setFecthParams(params);
            }}
          />
        )}
      </div>
    </div>
  );
}
