import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import { detailsStateSelect } from "../../selector";

import EmployerDetailsView from "./component";

const EmployerDetailsViewContainer = (props) => {
  const details = useSelector(detailsStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <EmployerDetailsView
      details={details}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default EmployerDetailsViewContainer;
