import { useState, useEffect } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Api } from "api";

export default function useFetchList(endpoint) {
  const [fetching, setFetching] = useState(false);
  const [count, setCount] = useState(0);
  const [value, setValue] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(endpoint);
        const { count, value } = data;
        setFetching(false);
        setCount(count);
        setValue(value);
      } catch (err) {
        if (
          err?.message === "User is not signed in" ||
          err?.message === "User is not authorized"
        ) {
          dispatch(push("/auth/signin"));
        }
        setFetching(false);
        // maybe display an error message
      }
    };

    fetchData();
  }, [dispatch, endpoint]);

  return { fetching, count, data: value };
}
