import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import routes from "./routes";
import { detailsStateSelect } from "./selector";

function HealthDocs({ match, location }) {
  const details = useSelector(detailsStateSelect);

  return (
    <div>
      <Helmet>
        <title>AGMA-BO</title>
        <meta name="Clients" content="Clients" />
      </Helmet>
      <Switch>
        {routes.map((route, i) => {
          if (location.pathname === "/clients") {
            return <Redirect key={route.path} to="/clients/list" />;
          }
          if (location.pathname === "/clients/details" && !details.current) {
            return <Redirect key={route.path} to="/clients/list" />;
          }
          return (
            <Route
              key={route.path}
              path={`${match.path}${route.path}`}
              render={(props) => <route.component {...props} />}
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default HealthDocs;
