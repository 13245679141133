// routes components
import Home from "./index";

export default [
  {
    path: "/home",
    component: Home,
    exact: true,
  },
];
