import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setExpandedGarantie,
  // updateExpandedGarantie,
  updateCurrentPack,
} from "../actions";

import { Api } from "api";

import { omit } from "lodash";

import {
  createFormuleUrl,
  updateFormuleUrl,
  createContrainteUrl,
  updateContrainteUrl,
  createDepUrl,
  updateDepUrl,
} from "../api/endpoints";

export function useUpdateGarantie() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  function createOrUpdateFormule(formules) {
    const requests = [];

    formules.forEach((f) => {
      if (f._id) {
        requests.push(
          Api().put(updateFormuleUrl(f._id), omit(f, ["_id", "id", "__v"]))
        );
      } else {
        requests.push(
          Api().post(createFormuleUrl(), omit({ ...f, type: "carte" }, "num"))
        );
      }
    });

    const promise = new Promise((resolve, reject) => {
      Promise.all(requests)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });

    return promise;
  }

  function createOrUpdateDeps(deps) {
    const requests = [];

    deps.forEach((f) => {
      if (f._id) {
        requests.push(
          Api().put(updateDepUrl(f._id), omit(f, ["_id", "id", "__v", "isG6"]))
        );
      } else {
        requests.push(Api().post(createDepUrl(), omit(f, "num")));
      }
    });

    const promise = new Promise((resolve, reject) => {
      Promise.all(requests)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });

    return promise;
  }

  function createOrUpdateConstraintes(contraintes, type = "carte") {
    const requests = [];

    contraintes.forEach((f) => {
      if (f._id) {
        requests.push(
          Api().put(updateContrainteUrl(f._id), omit(f, ["_id", "id", "__v"]))
        );
      } else {
        requests.push(
          Api().post(createContrainteUrl(), omit({ ...f, type }, "num"))
        );
      }
    });

    const promise = new Promise((resolve, reject) => {
      Promise.all(requests)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });

    return promise;
  }

  async function updateGarantie(paylaod, type = "carte") {
    try {
      setFetching(true);
      setError(null);

      const {
        formules = [],
        interdependances = [],
        contraintes = [],
      } = paylaod;
      // let newFormules;
      // let newDeps;
      let newConstraintes;

      if (contraintes.length) {
        newConstraintes = await createOrUpdateConstraintes(contraintes, type);
      }

      if (formules.length) {
        //newFormules =
        await createOrUpdateFormule(formules);
      }

      if (interdependances.length) {
        //newDeps =
        await createOrUpdateDeps(interdependances);
      }

      if (type === "carte") {
        // dispatch(
        //   updateExpandedGarantie({
        //     ...(newFormules && { formules: newFormules }),
        //     ...(newConstraintes && { contraintes: newConstraintes }),
        //     ...(newDeps && { interdependances: newDeps }),
        //   })
        // );
        dispatch(setExpandedGarantie(null));
      } else {
        dispatch(updateCurrentPack({ contraintes: newConstraintes }));
      }

      setFetching(false);
    } catch (err) {
      setFetching(false);
      setError(err);
    }
  }

  return [{ fetching, error }, updateGarantie];
}
