import React from "react";

import DcFORM from "./dcForm";
import OthersFORM from "./othersForm";

const ConstraintesForm = (props) => {
  return props.code === "g6" ? (
    <DcFORM {...props} />
  ) : (
    <OthersFORM {...props} />
  );
};

export default ConstraintesForm;
