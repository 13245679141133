import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import { createStateSelect } from "../../selector";

import EmployerDetailsView from "./component";

const EmployerDetailsViewContainer = (props) => {
  const create = useSelector(createStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <EmployerDetailsView create={create} actions={actionsDispatch} {...props} />
  );
};

export default EmployerDetailsViewContainer;
