// import { unionBy } from "lodash";

// declare actions types
import {} from "../actionsTypes";
import { LOGOUT_SUCCESS } from "actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  fetching: false,
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function list(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
