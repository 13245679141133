import React from "react";
import classNames from "classnames";

const Footer = ({ isSidebarOpen }) => {
  return (
    <footer className={classNames("footer app", { "is-open": isSidebarOpen })}>
      <div>© 2020 Groupe AGMA Assureur Conseil (v2.1.9)</div>
    </footer>
  );
};

export default Footer;
