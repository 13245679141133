// routes components
import List from "./containers/list";
import Details from "./containers/details";

export default [
  {
    path: "/list",
    component: List,
    exact: true,
  },
  {
    path: "/details",
    component: Details,
    exact: true,
  },
];
