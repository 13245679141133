import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import { authStateSelect } from "../../selector";

import LoginView from "./component";

const LoginViewContainer = (props) => {
  const auth = useSelector(authStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return <LoginView auth={auth} actions={actionsDispatch} {...props} />;
};

export default LoginViewContainer;
