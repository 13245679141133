import { useState, useEffect } from "react";

import { Api } from "api";

export default function useFetchElement(el, endpoint) {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(el);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(endpoint);
        setFetching(false);
        setData(data);
      } catch {
        setFetching(false);
        // maybe display an error message
      }
    };

    fetchData();
  }, [endpoint]);

  return { fetching, data };
}
