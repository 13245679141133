//router
import { push } from "connected-react-router";

// api
import { Api } from "api";

// endpoints
import { clientByIdUrl } from "./api/endpoints";

import {
  SET_CURRENT_CLIENT,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
} from "./actionsTypes";

export const setCurrentDoc = (payload) => ({
  type: SET_CURRENT_CLIENT,
  payload,
});

const updateClientRequest = () => ({
  type: UPDATE_CLIENT_REQUEST,
});

const updateClientSuccess = (payload) => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload,
});

const updateClientFailed = () => ({
  type: UPDATE_CLIENT_FAILED,
});

export const updateClient = (id, payload) => {
  return async (dispatch) => {
    try {
      dispatch(updateClientRequest);
      const data = await Api().put(clientByIdUrl(id), payload);
      console.log("update success", data);
      dispatch(updateClientSuccess(data));
    } catch (error) {
      dispatch(updateClientFailed());
    }
  };
};
