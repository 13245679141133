import React from "react";
import { Row, Col /*, Button */ } from "reactstrap";
import Avatar from "@material-ui/core/Avatar";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

// endpoints
// import { clientByIdUrl } from "../../api/endpoints";

import "assets/css/elementDetails.css";

const PrimarySwitch = withStyles({
  switchBase: {
    color: "#037cc4",
    "&$checked": {
      color: "#009ee4",
    },
    "&$checked + $track": {
      backgroundColor: "#009ee4",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function DocDetails(props) {
  const { current } = props.details;
  // const { data: user /*, fetching */ } = useFetchElement(
  //   current,
  //   clientByIdUrl(current.id)
  // );

  console.log("current", current);

  const handleChange = () => {
    props.actions.updateClient(current._id, {
      accountEnabled: !current.accountEnabled,
    });
  };

  return (
    <div className="app-main">
      <div>
        <Row>
          <Col md="8">
            <div className="info-view">
              <div className="info-key">Nom & Prénom:</div>
              <div className="info-value">
                <h5>{current?.name.full}</h5>
              </div>
            </div>
            <div className="info-view">
              <div className="info-key">Email:</div>
              <div className="info-value">
                <h5>{current?.email}</h5>
              </div>
            </div>
            <div className="info-view">
              <div className="info-key">Téléphone:</div>
              <div className="info-value">
                <h5>{current?.phone}</h5>
              </div>
            </div>
            <div className="info-view">
              <div className="info-key">Employeur:</div>
              <div className="info-value">
                <h5>{current?.employer?.name}</h5>
                <Avatar
                  variant="rounded"
                  alt="logo"
                  src={`${
                    process.env.REACT_APP_DOMAIN
                  }${current?.employer?.imageUrl.replace("size=300x300", "")}`}
                />
              </div>
            </div>
            <div className="info-view">
              <div className="info-key">Statut:</div>
              <div className="info-value aling-end">
                {/*<h5>current?.employer?.name</h5>*/}
                <PrimarySwitch
                  value={current?.accountEnabled}
                  onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
