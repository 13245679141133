import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

/*
 * Import reducers
 */
import app from "./appReducer";
import auth from "modules/Auth/reducers";
import clients from "modules/Clients/reducers";
import societes from "modules/Societes/reducers";
import conventions from "modules/Conventions/reducers";
import demandes from "modules/Demandes/reducers";

const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    clients,
    societes,
    conventions,
    demandes,
  });

export default createReducers;
