export default function capitalize(str, i) {
  if (i) {
    return str.slice(0, i) + str.charAt(i).toUpperCase() + str.slice(i + 1);
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeAll(string) {
  let parts = string.split(" ").map((str, index) => {
    return capitalize(str.toLowerCase());
  });
  return parts.join(" ");
}
