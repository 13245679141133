//router
import { push } from "connected-react-router";

// api
import { Api } from "api";

import { uploadFiles } from "actions";

// // endpoints
import { updateUrl, createUrl } from "./api/endpoints";

import {
  SET_CURRENT_CLIENT,
  UPDATE_EMPLOYER_REQUEST,
  UPDATE_EMPLOYER_SUCCESS,
  UPDATE_EMPLOYER_FAILED,
} from "./actionsTypes";

export const setCurrentDoc = (payload) => ({
  type: SET_CURRENT_CLIENT,
  payload,
});

const updateEmployerRequest = () => ({
  type: UPDATE_EMPLOYER_REQUEST,
});

const updateEmployerSuccess = () => ({
  type: UPDATE_EMPLOYER_SUCCESS,
});

const updateEmployerFailed = () => ({
  type: UPDATE_EMPLOYER_FAILED,
});

export const updateEmployer = (id, payload, filePayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateEmployerRequest);
      let image;
      if (filePayload) {
        image = await uploadFiles(filePayload);
      }
      const nPayload = { ...payload, ...(image && { image: image.file }) };
      let data;
      if (id) {
        data = await Api().put(updateUrl(id), nPayload);
      } else {
        data = await Api().post(createUrl(), nPayload);
      }
      console.log("update success", data);
      dispatch(updateEmployerSuccess(data));
      dispatch(push("/societes/list"));
      // if (!id) {

      // }
    } catch (error) {
      dispatch(updateEmployerFailed());
    }
  };
};
