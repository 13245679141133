import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Spinner,
} from "reactstrap";

import Footer from "components/Footer";
import "assets/css/auth.css";

function Loginview(props) {
  const { path } = props.match;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openPassword, setOpenPassword] = useState(false);

  const formValid = password !== "" && username !== "";

  useEffect(() => {
    props.actions.clearAuthState();
  }, [path, props.actions]);

  const onClickForgot = (e) => {
    e.preventDefault();
    props.actions.resetForgot();
    props.history.push("/auth/forgot");
  };

  const onSendRequest = (e) => {
    e.preventDefault();
    const payload = {
      username,
      password,
    };

    props.actions.signin(payload);
  };

  return (
    <>
      <Container>
        <Row>
          <Col sm={{ size: 6, offset: 3 }} className="login-form">
            <div className="auth-logo-container">
              <img
                className="login-logo"
                alt="..."
                src={require("assets/img/login_logo.png")}
              ></img>
            </div>
            <h2 className="text-left login-text-bold form-title">
              S'identifier
            </h2>
            <Card className="card-login card-plain">
              <Form action="" className="form" method="">
                <CardBody>
                  <InputGroup className={"input-lg"}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className={"bg-input"}>
                        <img
                          alt="..."
                          className="input-icon"
                          src={require("assets/img/cin.png")}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Login"
                      type="email"
                      name="login-username"
                      className={"login-input bg-input"}
                      value={username}
                      onChange={(e) => {
                        e.preventDefault();
                        setUsername(e.currentTarget.value);
                      }}
                    ></Input>
                  </InputGroup>
                  <InputGroup className={"input-lg"}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className={"bg-input"}>
                        <img
                          alt="..."
                          className="input-icon"
                          src={require("assets/img/password.png")}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="userPassword"
                      placeholder="Mot de passe"
                      type={openPassword ? "text" : "password"}
                      className="login-input bg-input"
                      value={password}
                      onChange={(e) => {
                        e.preventDefault();
                        setPassword(e.currentTarget.value);
                      }}
                    ></Input>
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        onClick={() => setOpenPassword(!openPassword)}
                        className={"bg-input"}
                      >
                        <img
                          alt="+"
                          src={
                            openPassword
                              ? require("assets/img/eye-b.png")
                              : require("assets/img/eye.png")
                          }
                          className="input-icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="card-footer-buttons">
                    {props.auth.requestError && (
                      <h6 className="contact-info">
                        {props.auth.errorMessage}
                      </h6>
                    )}
                    <Button
                      block
                      className="btn-round btn-login"
                      color="neutral"
                      onClick={(e) => onSendRequest(e)}
                      size="lg"
                      disabled={props.auth.fetching || !formValid}
                    >
                      {props.auth.fetching ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Se connecter"
                      )}
                    </Button>
                    <div tag="button" onClick={onClickForgot}>
                      <h6 className="forgot-button">
                        J'ai oublié mon mot de passe
                      </h6>
                    </div>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Loginview;
