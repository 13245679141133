import React, { useEffect, useState } from "react";
import { Alert, Button, Spinner } from "reactstrap";

import { FORMULE } from "config/app";

import FormuleForm from "../forms/formuleForms";

import { useUpdateGarantie } from "../../../hooks/useUpdateGarantie";

const SectionContent = (props) => {
  const { code, formules: gFormules, _id: gId } = props.garantie;
  const [formules, setFormules] = useState([]);

  const [{ fetching, error }, updateGarantie] = useUpdateGarantie();

  useEffect(() => {
    setFormules(
      gFormules?.length
        ? gFormules
        : [{ ...FORMULE, num: Math.random(), garantie: gId }]
    );
  }, [gFormules, gId]);

  const onUpdate = () => {
    updateGarantie({
      formules,
      interdependances: [],
      contraintes: [],
    });
  };

  const renderFormules = () => {
    return (
      <div className="formule-view">
        {/*<span>Formules </span>*/}
        {formules.map((f, i) => (
          <div key={f._id || f.num.toString()} className="formule-view-item">
            {/*<div
              onClick={() => onRemoveFormule(i, f._id)}
              className="formule-rm"
            >
              <img
                alt="x"
                src={require("assets/img/rm-icon.png")}
                className="rm-icon"
              />
            </div>*/}
            <div style={{ flex: 1 }}>
              {/*<span className="formule-title">{`Formule ${i + 1}`}</span>*/}
              <FormuleForm
                key={f._id || f.num.toString()}
                code={code}
                formule={f}
                onFormChange={(form) => {
                  const old = formules;
                  old.splice(i, 1, form);
                  setFormules(old);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (props.fetching) {
    return (
      <div className="section-content-view">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="section-content-view">
      {props.readonly ? null : renderFormules()}

      {props.readonly ? null : (
        <Button
          className="btn-round"
          color="primary"
          onClick={(e) => {
            onUpdate();
          }}
          size="lg"
        >
          {fetching ? (
            <Spinner size="sm" color="light" />
          ) : (
            "Sauvegarder cette garantie"
          )}
        </Button>
      )}
      {error ? (
        <Alert color="danger">
          {error.message || "Un erreur est survenue. Veuillez réessayer"}
        </Alert>
      ) : null}
    </div>
  );
};

export default SectionContent;
