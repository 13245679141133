import React from "react";
import { Row, Col } from "reactstrap";

const PageDescription = (props) => {
  return (
    <div className="page-description">
      <Row>
        <Col md="7" className="page-description-content">
          <div>
            <h3 className="page-title-text">{props.title}</h3>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PageDescription;
