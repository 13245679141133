import React, { useState, useEffect } from "react";

import { CONSTRAINTES } from "config/app";

import AppInput from "components/AppInput";
import AppDropDown from "components/AppDropDown";

const ConstraintesForm = ({ onFormChange, ...props }) => {
  const [initFactor, setInitFactor] = useState(null);
  const [state, setState] = useState(props.contrainte);
  const [contraintes, setContraintes] = useState(CONSTRAINTES);

  useEffect(() => {
    const c = contraintes.find((one) => one.value === props.contrainte.factor);
    if (c) {
      setInitFactor(c);
    }
  }, [contraintes, props.contrainte.factor]);

  useEffect(() => {
    const factors = props.selected.map((one) => one.factor);
    setContraintes(CONSTRAINTES.filter((one) => !factors.includes(one.value)));
  }, [props.selected]);

  useEffect(() => {
    onFormChange({
      ...state,
      factor: state.factor?.value || state.factor,
    });
  }, [onFormChange, state]);

  return (
    <div className="section-constraint-view">
      <div className="creation-element space-right">
        <h5>Type</h5>
        <AppDropDown
          selected={
            typeof state.factor === "string" ? initFactor : state.factor
          }
          options={contraintes}
          setSelected={(value) => setState({ ...state, factor: value })}
          size="xs"
        />
      </div>
      <div className="creation-element space-right">
        <h5>Minimum</h5>
        <AppInput
          size="lg"
          value={state.min}
          onChange={(t) => setState({ ...state, min: t ? parseFloat(t) : "" })}
          type="number"
        />
      </div>
      <div className="creation-element space-right">
        <h5>Maximum</h5>
        <AppInput
          size="lg"
          value={state.max}
          onChange={(t) => setState({ ...state, max: t ? parseFloat(t) : "" })}
          type="number"
        />
      </div>
    </div>
  );
};

export default ConstraintesForm;
