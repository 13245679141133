import React, { useState, useEffect } from "react";

import { ASSIETTES_TARIF } from "config/app";

import AppInput from "components/AppInput";
import AppDropDown from "components/AppDropDown";

const PLAFOND_TYPES = [
  { label: "Texte", value: "TXT" },
  { label: "Valeur neuve", value: "VN" },
  { label: "Valeur vénale", value: "VV" },
  { label: "VDG", value: "VDG" },
];

const FormuleForm = (props) => {
  const [initAssiete, setInitAssiete] = useState(null);
  const [initPlafondType, setInitPlafondType] = useState(null);
  const [state, setState] = useState(props.formule);
  const { onFormChange } = props;

  useEffect(() => {
    const c = PLAFOND_TYPES.find(
      (one) => one.value === props.formule.desc_plafond_type
    );
    if (c) {
      setInitPlafondType(c);
    }
  }, [props.formule.desc_plafond_type]);

  useEffect(() => {
    const c = ASSIETTES_TARIF.find(
      (one) => one.value === props.formule.tarif_normal.assiete
    );
    if (c) {
      setInitAssiete(c);
    }
  }, [props.formule.tarif_normal.assiete]);

  useEffect(() => {
    onFormChange({
      ...state,
      desc_plafond_type:
        state.desc_plafond_type.value || state.desc_plafond_type,
      tarif_normal: {
        ...state.tarif_normal,
        tarif_ht: state.tarif_normal.tarif_ht ? state.tarif_normal.tarif_ht : 0,
        prim_ht: state.tarif_normal.prim_ht ? state.tarif_normal.prim_ht : 0,
        assiete: state.tarif_normal.assiete.value || state.tarif_normal.assiete,
      },
    });
  }, [onFormChange, state]);

  const renderTarifs = () => (
    <div className="tarifs-view">
      <div className="creation-element space-right">
        <h5>Tarif HT (%)</h5>
        <AppInput
          size="lg"
          value={state.tarif_normal.tarif_ht}
          onChange={(t) =>
            setState({
              ...state,
              tarif_normal: {
                ...state.tarif_normal,
                tarif_ht: t ? parseFloat(t) : "",
              },
            })
          }
          type="number"
        />
      </div>
      <div className="creation-element space-right">
        <h5>Prime HT minimun </h5>
        <AppInput
          size="lg"
          value={state.tarif_normal.prim_ht}
          onChange={(t) =>
            setState({
              ...state,
              tarif_normal: {
                ...state.tarif_normal,
                prim_ht: t ? parseFloat(t) : "",
              },
            })
          }
          type="number"
        />
      </div>
      <div className="creation-element space-right">
        <h5>Assiette d'application du % de tarif</h5>
        <AppDropDown
          selected={
            typeof state.tarif_normal.assiete === "string"
              ? initAssiete
              : state.tarif_normal.assiete
          }
          options={ASSIETTES_TARIF}
          setSelected={(value) =>
            setState({
              ...state,
              tarif_normal: { ...state.tarif_normal, assiete: value },
            })
          }
          size="xs"
        />
      </div>
    </div>
  );

  const renderPlafondContent = () => {
    if (
      state.desc_plafond_type?.value === "TXT" ||
      state.desc_plafond_type === "TXT"
    ) {
      return (
        <div style={{ flex: 1 }} className="creation-element space-left">
          <AppInput
            size="md"
            value={state.desc_plafond_content}
            onChange={(t) => setState({ ...state, desc_plafond_content: t })}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="section-form-view creation-element">
      <h5>Description du plafond de la garantie</h5>
      <div className="formule-container">
        <div className="creation-element">
          <AppDropDown
            selected={
              typeof state.desc_plafond_type === "string"
                ? initPlafondType
                : state.desc_plafond_type
            }
            options={PLAFOND_TYPES}
            setSelected={(value) =>
              setState({ ...state, desc_plafond_type: value })
            }
            size="xs"
          />
        </div>
        {renderPlafondContent()}
      </div>
      <div className="creation-element">
        <h5>Description de la Franchise</h5>
        <AppInput
          size="lg"
          value={state.desc_franchise}
          onChange={(t) => setState({ ...state, desc_franchise: t })}
        />
      </div>

      {renderTarifs()}
    </div>
  );
};

export default FormuleForm;
