import React, { useState, useEffect, useCallback } from "react";

import StepFooter from "./stepFooter";
import TabsView from "../tabsView";
import GarantiesConfig from "../../garanties";
import PacksConfig from "../../packs";

import { useFetchGaranties } from "../../../hooks/useFetchGaranties";

const Step2 = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [{ fetching, error }, fetchGaranties] = useFetchGaranties();
  const { current } = props.create;

  useEffect(() => {
    const packsConfig = current.hasPacks
      ? current.packs.map((p, i) => ({
          name: p.name || `Pack ${i + 1}`,
          _id: p._id || p,
        }))
      : [];
    const carteConfig = current.hasCarte
      ? [{ name: "À la carte", _id: current.carte }]
      : [];
    const configTabs = [...carteConfig, ...packsConfig];
    setTabs(configTabs);
  }, [current.carte, current.hasCarte, current.hasPacks, current.packs]);

  const didMount = useCallback(() => {
    const fetchType = current.hasCarte && activeTab === 0 ? "cartes" : "packs";
    if (tabs[activeTab] && tabs[activeTab]._id) {
      fetchGaranties(fetchType, tabs[activeTab]._id);
    }
  }, [activeTab, current.hasCarte, fetchGaranties, tabs]);

  useEffect(() => {
    didMount();
  }, [didMount]);

  const handleChange = (newValue) => {
    setActiveTab(newValue);
    // const type = current.hasCarte && newValue === 0 ? "cartes" : "packs";
    // const fId = tabs[newValue]._id;
    // fetchGaranties(type, fId);
  };

  return (
    <>
      <TabsView tabs={tabs} onChange={handleChange} />
      <div className="conv-tab-content">
        {current.hasCarte && activeTab === 0 ? <GarantiesConfig /> : null}
        {current.packs.map((pack) => {
          const tabIndex = tabs.findIndex(
            (one) => one._id === (pack?._id || pack)
          );
          const display = pack && activeTab === tabIndex;
          return display ? (
            <PacksConfig
              key={pack?._id || Math.random()}
              pack={pack?._id || pack}
            />
          ) : null;
        })}
      </div>
      <StepFooter
        previous
        next="/conventions/create#assistance"
        // nextDisabled={invalidForm}
        history={props.history}
        onCancel={props.onCancel}
      />
    </>
  );
};

export default Step2;
