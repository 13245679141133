import React, { useState, useEffect } from "react";
import { Api } from "api";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
  setCurrentUser,
  getCurrentUserRequest,
  getCurrentUserFailed,
} from "actions";

const userStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

const userFecthStateSelect = createSelector(
  (state) => state.app,
  (app) => app.fetchingUser
);

export function useCheckAuth(pathname) {
  const currentUser = useSelector(userStateSelect);
  const fetching = useSelector(userFecthStateSelect);
  const [isConnected, setConnected] = useState(currentUser !== null);
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAuth() {
      try {
        dispatch(getCurrentUserRequest());
        const user = await Api().get("/me");
        console.log("got user", user);
        setConnected(true);
        if (user) {
          dispatch(setCurrentUser(user));
        }
      } catch (e) {
        console.log("e", e);
        setConnected(false);
        dispatch(getCurrentUserFailed());
      } finally {
        setIsReady(true);
      }
    }
    if (!fetching && !currentUser) {
      checkAuth();
    } else {
      if (!fetching) {
        setConnected(true);
        setIsReady(true);
      }
    }
  }, [currentUser, dispatch, fetching, isConnected, isReady]);

  return { isConnected, isReady };
}

export default function CheckAuth(Component, pathname, ...props) {
  const { isConnected, isReady } = useCheckAuth(pathname);
  if (isReady) {
    return <Component isConnected={isConnected} {...props} />;
  }

  return <div />;
}
