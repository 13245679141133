import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";

const ConfirmModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={() => {
        props.onCloseModal();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title">Confirmation</h5>
        <button
          onClick={(e) => {
            props.onCloseModal();
          }}
          aria-label="Close"
          className="close"
          type="button"
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <p>Souhaitez-vous vraiment annuler la création ?</p>
      </ModalBody>
      <div className="modal-footer logout">
        <Button
          className="btn-round logout"
          color="primary"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            props.onConfirm();
          }}
        >
          Oui
        </Button>
        <Button
          className="btn-round logout"
          onClick={(e) => {
            e.preventDefault();
            props.onCloseModal();
          }}
          color="secondary"
          type="button"
        >
          Non
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
