//router
// import { push } from "connected-react-router";

import { Api } from "api";
import {
  SET_CURRENT_CONV,
  SET_CREATED_CONV,
  CLEAR_CREATION_FORM,
  UPDATE_CREATION_FORM,
  SET_CONV_GARANTIES,
  SET_EXPANDED_GARANTIE,
  UPDATE_EXPANDED_GARANTIE,
  UPDATE_CURRENT_PACK,
} from "./actionsTypes";

import {
  updateFormuleUrl,
  updateContrainteUrl,
  updateDepUrl,
  updateGarantieUrl,
  conventionByIdUrl,
  packByIdUrl,
  carteByIdUrl,
} from "./api/endpoints";

export const setCurrentConv = (payload) => ({
  type: SET_CURRENT_CONV,
  payload,
});

export const setCreatedConv = (payload) => ({
  type: SET_CREATED_CONV,
  payload,
});

export const clearCreationForm = () => ({
  type: CLEAR_CREATION_FORM,
});

export const updateCreationForm = (payload) => ({
  type: UPDATE_CREATION_FORM,
  payload,
});

export const setGaranties = (payload) => ({
  type: SET_CONV_GARANTIES,
  payload,
});

export const setExpandedGarantie = (payload) => ({
  type: SET_EXPANDED_GARANTIE,
  payload,
});

export const updateExpandedGarantie = (payload) => ({
  type: UPDATE_EXPANDED_GARANTIE,
  payload,
});

export const setEnabled = (id, payload) => {
  return async (dispatch) => {
    try {
      await Api().put(updateGarantieUrl(id), payload);
    } catch {}
  };
};

export const removeFormule = (id) => {
  return async (dispatch) => {
    try {
      await Api().delete(updateFormuleUrl(id));
    } catch {}
  };
};

export const removeContrainte = (id) => {
  return async (dispatch) => {
    try {
      await Api().delete(updateContrainteUrl(id));
    } catch {}
  };
};

export const removeDependance = (id) => {
  return async (dispatch) => {
    try {
      await Api().delete(updateDepUrl(id));
    } catch {}
  };
};

export const removePack = (id) => {
  return async (dispatch) => {
    try {
      await Api().delete(packByIdUrl(id));
    } catch {}
  };
};

export const removeCarte = (id) => {
  return async (dispatch) => {
    try {
      await Api().delete(carteByIdUrl(id));
    } catch {}
  };
};

export const updateCurrentPack = (payload) => ({
  type: UPDATE_CURRENT_PACK,
  payload,
});

export const updateConvention = (id, payload) => {
  return async (dispatch) => {
    try {
      await Api().put(conventionByIdUrl(id), payload);
    } catch {}
  };
};

export const deleteConvention = (id) => {
  return async (dispatch) => {
    try {
      await Api().delete(conventionByIdUrl(id));
    } catch {}
  };
};
