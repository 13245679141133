//router
import { replace } from "connected-react-router";

// api
import { Api } from "api";

import { setCurrentUser } from "actions";

// import front-end config
import {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
  FORGOT_PASS_FAILURE,
  RESET_FORGOT_FORM,
  CLEAR_AUTH_STATE,
} from "./actionsTypes";

import { loginUrl, forgotUrl } from "./api/endpoints";

export const clearAuthState = () => ({
  type: CLEAR_AUTH_STATE,
});

export const signinRequest = () => ({
  type: SIGNIN_REQUEST,
});

export const signinSuccess = (payload) => ({
  type: SIGNIN_SUCCESS,
  payload,
});

export const signinFailure = (payload) => ({
  type: SIGNIN_FAILURE,
  payload,
});

export const signin = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(signinRequest());
      const data = await Api().post(loginUrl(), payload);
      console.log("signin data", data);
      dispatch(signinSuccess());
      dispatch(setCurrentUser(data));
      dispatch(replace("/societes"));
    } catch (e) {
      dispatch(signinFailure(e ? e.message : "Erreur de connexion"));
    }
  };
};

export const resetForgot = () => ({
  type: RESET_FORGOT_FORM,
});

export const forgotPassRequest = () => ({
  type: FORGOT_PASS_REQUEST,
});

export const forgotPassSuccess = (payload) => ({
  type: FORGOT_PASS_SUCCESS,
  payload,
});

export const forgotPassFailure = (payload) => ({
  type: FORGOT_PASS_FAILURE,
  payload,
});

export const forgotPassword = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(forgotPassRequest());
      const data = await Api().post(forgotUrl(), payload);
      dispatch(forgotPassSuccess(data.email));
    } catch (e) {
      dispatch(forgotPassFailure(e ? e.message : "Erreur de connexion"));
    }
  };
};
