import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import PageDescription from "components/PageDescription";

// home sections
import { currentUserSelect } from "./selector";

function App({ history, actions }) {
  const currentUser = useSelector(currentUserSelect);
  return (
    <div className="App">
      <Helmet>
        <title>Accueil</title>
        <meta name="description" content="AFMA Web" />
      </Helmet>
      <div className="app-main">
        <PageDescription title="Accueil" />
      </div>
    </div>
  );
}

export default App;
