import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classNames from "classnames";

import "assets/css/appDropdown.css";

const DDInput = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    console.log("toggling");
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        aria-expanded={false}
        aria-haspopup={true}
        data-toggle="dropdown"
        data-stripe={props.dataStripe}
        href="#pablo"
        id="dropdownMenuLink"
        onClick={(e) => e.preventDefault()}
        className={classNames("app-dropdown", {
          lg: props.size === "lg",
          sm: props.size === "sm",
          xs: props.size === "xs",
        })}
      >
        {props.selected?.label || props.placeholder || "Choisir"}
        {props.arrowDisplay && (
          <img
            src={require("assets/img/dropdown.png")}
            className="dropdown-arrow"
            alt=">"
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="dropdown-options">
        {props.options.map((option) => (
          <DropdownItem
            key={option.value}
            onClick={(e) => props.setSelected(option)}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

DDInput.defaultProps = {
  arrowDisplay: true,
  size: "sm",
};

export default DDInput;
