import React from "react";

import Footer from "components/Footer";
import Back from "components/Back";
import RequestForm from "./requestForm";
import SuccessView from "./successView";

import "assets/css/auth.css";

function ForgotPassView(props) {
  return (
    <>
      <Back history={props.history} />
      {props.auth.forgotState === "request" && <RequestForm {...props} />}
      {props.auth.forgotState === "success" && <SuccessView {...props} />}
      <Footer />
    </>
  );
}

export default ForgotPassView;
