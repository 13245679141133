export const SUPPORT_CONTACT = "0802002223";

export const DEPENDENCIES = [
  { label: "Souscrite par défaut", value: "DEFAULT" },
  { label: "Incluse dans une autre garantie", value: "INCL" },
  { label: "Exige une autre garantie", value: "EXIG" },
  { label: "Exclue une autre garantie", value: "EXCL" },
];

export const CONSTRAINTES = [
  { label: "Âge du véhicule", value: "AGE" },
  { label: "Valeur neuve", value: "VN" },
  { label: "Valeur vénale", value: "VV" },
  { label: "VDG", value: "VDG" },
];

export const DC_CONSTRAINTES = [
  { label: "Plafond", value: "PLAFOND" },
  { label: "Âge du véhicule", value: "AGE" },
  { label: "Valeur neuve", value: "VN" },
  { label: "Valeur vénale", value: "VV" },
  { label: "VDG", value: "VDG" },
];

export const ASSIETTES_TARIF = [
  { label: "N/A", value: "NA" },
  { label: "Valeur neuve", value: "VN" },
  { label: "Valeur vénale", value: "VV" },
  { label: "VDG", value: "VDG" },
];

export const ASSISTANCE = { titre: "", description: "", prix: "" };

export const DEPENDANCE = {
  type: "",
  target: "",
};

export const CONTRAINTE = {
  // type: "carte",
  // garantie: "5f15e217db3915f3b1658b18",
  // carte: "5f15e217db3915f3b1658b18",
  // pack: "5f15e217db3915f3b1658b18",
  factor: "",
  isG6: false,
  min: 0,
  max: 0,
  percent: 0,
};

export const FORMULE = {
  tarif_normal: {
    enabled: true,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  tarif_ess_6cv: {
    enabled: false,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  tarif_ess_7_8cv: {
    enabled: false,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  tarif_ess_9_10cv: {
    enabled: false,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  tarif_ess_10cv: {
    enabled: false,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  tarif_gas_4cv: {
    enabled: false,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  tarif_gas_5cv: {
    enabled: false,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  tarif_gas_6_7cv: {
    enabled: false,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  tarif_gas_8cv: {
    enabled: false,
    tarif_ht: 0,
    prim_ht: 0,
    assiete: "NA",
  },
  isG6: false,
  desc_plafond_type: "TXT",
  desc_plafond_content: "",
  desc_franchise: "",
};

export const GARANTIES = [
  {
    name: "Responsabilité Civile",
    code: "g1",
    acceptFormules: false,
    enabledByDefault: true,
  },
  {
    name: "Evènement catastrophique",
    code: "g2",
    acceptFormules: false,
    enabledByDefault: true,
  },
  {
    name: "Défense et recours",
    code: "g3",
    acceptFormules: true,
  },
  {
    name: "Personnes transportées (PTA)",
    code: "g4",
    acceptFormules: true,
  },
  {
    name: "Tierce (Dommages Tous Accidents)",
    code: "g5",
    acceptFormules: true,
  },
  {
    name: "Dommage Collision (DC)",
    code: "g6",
    acceptFormules: true,
  },
  {
    name: "Vol",
    code: "g7",
    acceptFormules: true,
  },
  {
    name: "Vol des rétroviseurs",
    code: "g8",
    acceptFormules: true,
  },
  {
    name: "Accessoires extérieurs",
    code: "g9",
    acceptFormules: true,
  },
  {
    name: "Incendie",
    code: "g10",
    acceptFormules: true,
  },
  {
    name: "Bris de glace",
    code: "g11",
    acceptFormules: true,
  },
  {
    name: "Bris des rétroviseurs",
    code: "g12",
    acceptFormules: true,
  },
  {
    name: "Evènements climatiques",
    code: "g13",
    acceptFormules: true,
  },
  {
    name: "Inondation",
    code: "g14",
    acceptFormules: true,
  },
  {
    name: "Rachat de vétusté",
    code: "g15",
    acceptFormules: true,
  },
  {
    name: "Perte financière",
    code: "g16",
    acceptFormules: true,
  },
  // {
  //   name: "Assistance",
  //   code: "g17",
  //   acceptFormules: true,
  // },
  {
    name: "Vol des pneumatiques",
    code: "g17",
    acceptFormules: true,
  },
  {
    name: "Dommage Collision Etendue",
    code: "g18",
    acceptFormules: true,
  },
  {
    name: "Autres Garanties",
    code: "g19",
    acceptFormules: true,
  },
  {
    name: "Garantie 20",
    code: "g20",
    acceptFormules: true,
  },
];
