import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import { createStateSelect, garantiesStateSelect } from "../../selector";

import PacksView from "./component";

const PacksViewContainer = (props) => {
  const create = useSelector(createStateSelect);
  const garanties = useSelector(garantiesStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <PacksView
      garanties={garanties}
      create={create}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default PacksViewContainer;
