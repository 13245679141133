import React from "react";
import { Input } from "reactstrap";
import { oneOf } from "prop-types";
import classNames from "classnames";

import "assets/css/appInput.css";

const AppInput = (props) => {
  return (
    <Input
      className={classNames("app-input", {
        lg: props.size === "lg",
        md: props.size === "md",
        sm: props.size === "sm",
      })}
      name={props.name || `_input`}
      id={props.id}
      placeholder={props.placeholder}
      type={props.type || "text"}
      value={props.value}
      onChange={(e) => {
        e.preventDefault();
        props.onChange(e.currentTarget.value);
      }}
      autoComplete="off"
      step=".01"
      disabled={props.disabled}
    ></Input>
  );
};

AppInput.propTypes = {
  size: oneOf(["lg", "md", "sm"]),
};

export default AppInput;
