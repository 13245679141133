import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const brdMap = [
  {
    path: "/home",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem active>Accueil</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/clients/list",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Clients</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/clients/details",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="#/clients/list">Clients</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Détail Client</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/societes/list",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Sociétes</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/societes/create",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Nouvelle société</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/societes/details",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="#/societes/list">Sociétes</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Détail Sociéte</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/conventions/list",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Conventions</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/conventions/details",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="#/conventions/list">Conventions</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Détail Convention</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/conventions/create",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="#/conventions/list">Conventions</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Nouvelle Convention</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/requests/list",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Conventions</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/requests/details",
    display: true,
    component: () => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#/home">Accueil</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="#/requests/list">Demandes</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Détail demande</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
];

export default brdMap;
