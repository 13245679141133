import { useState } from "react";
import { useDispatch } from "react-redux";

import { uploadFiles } from "actions";

import { Api } from "api";

import { createUrl, conventionByIdUrl } from "../api/endpoints";
import { setCreatedConv } from "../actions";

export function useCreate() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  function uploadFile(packs) {
    const requests = [];

    packs.forEach((pack) => {
      let uploadPayload = new FormData();
      if (pack.imageUrl?.file) {
        uploadPayload.append("file", pack.imageUrl.file);
        requests.push(uploadFiles(uploadPayload));
      }
    });

    const promise = new Promise((resolve, reject) => {
      Promise.all(requests).then((dataP) => {
        const packConfig = packs.map((pck, i) => {
          return {
            name: pck.name,
            image: dataP[i]?.file,
          };
        });
        resolve(packConfig);
      });
    });

    return promise;
  }

  async function createConvention(payload, conv) {
    try {
      setFetching(true);
      setError(null);
      const { packs } = payload;
      let uploaded, newPacks, packConfig;
      if (packs && packs.length) {
        uploaded = packs.filter((one) => one._id);
        newPacks = packs.filter((one) => !one._id);
        packConfig = await uploadFile(newPacks);
      }

      const updatedPayload = {
        ...payload,
        ...(packConfig &&
          packConfig.length && {
            packs: uploaded.concat(packConfig),
          }),
      };
      let data;
      if (conv) {
        data = await Api().put(conventionByIdUrl(conv), updatedPayload);
      } else {
        data = await Api().post(createUrl(), updatedPayload);
      }
      setFetching(false);
      setData(data);
      dispatch(setCreatedConv(data));
    } catch (err) {
      setFetching(false);
      setError(err);
    }
  }

  return [{ fetching, error, data }, createConvention];
}
