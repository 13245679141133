import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input } from "reactstrap";
import Avatar from "@material-ui/core/Avatar";

import AsyncSelect from "react-select/async";

import PageDescription from "components/PageDescription";

// some hooks
import useFetchElement from "hooks/useFetchElement";

// endpoints
import { Api } from "api";
import { conventionByIdUrl } from "../../api/endpoints";

import "assets/css/elementDetails.css";

export default function DocDetails(props) {
  const { current } = props.details;
  const { data: employer /*, fetching */ } = useFetchElement(
    current,
    conventionByIdUrl(current.id)
  );
  const [name, setName] = useState(employer?.name);
  const [convention, setConvention] = useState(employer?.convention);
  const [logo, setLogo] = useState(employer?.imageUrl);

  const onSave = () => {
    let filePayload;
    if (logo.file) {
      filePayload = new FormData();
      filePayload.append("file", logo.file);
    }

    const payload = {
      name,
      ...(convention && { convention }),
    };

    props.actions.updateEmployer(payload, filePayload);
  };

  const onSelectFile = (e) => {
    document.getElementById("file-select").click();
  };

  const loadOptions = (inputValue) =>
    new Promise(async (resolve) => {
      try {
        const filter = inputValue ? `$filter={"name": "${inputValue}"}` : "";
        const data = await Api().get(`/admin/conventions/?${filter}`);
        const formatted = data.value.map((one) => ({
          value: one._id,
          label: one.name,
        }));
        resolve(formatted);
      } catch {
        resolve([]);
      }
    });

  return (
    <div className="app-main">
      <PageDescription
        icon={require("assets/img/heart.png")}
        title={`Société > ${current.name}`}
      />
      <Row>
        <Col sm="10">
          <div className="details-content-view">
            <div className="details-content">
              <div className="details-content-value">
                <h5>Nom de la société</h5>
              </div>
              <Input
                placeholder=""
                type="text"
                name="employer-name"
                className={"details-input"}
                value={name}
                onChange={(e) => {
                  e.preventDefault();
                  setName(e.currentTarget.value);
                }}
              ></Input>
            </div>
            <div className="details-content">
              <div className="details-content-value">
                <h5>Logo de la société</h5>
              </div>
              <div className="logo-edit">
                <Avatar
                  variant="rounded"
                  alt="logo"
                  src={logo.url || `${logo}`}
                />
                <div
                  tag="button"
                  className="file-input-view"
                  onClick={onSelectFile}
                >
                  <img
                    alt=".."
                    src={require("assets/img/file-edit.png")}
                    className="file-icon"
                  />
                  <input
                    className="file-input-hidden"
                    type="file"
                    id="file-select"
                    onChange={(e) => {
                      e.preventDefault();

                      const file = e.target.files[0];
                      const url = URL.createObjectURL(file);

                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setLogo({ file, url });
                      };

                      reader.readAsDataURL(file);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="details-content">
              <div className="details-content-value">
                <h5>Convention</h5>
              </div>
              <AsyncSelect
                placeholder=""
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                className="details react-select react-select-primary"
                classNamePrefix="details.react-select"
                onChange={(select) => setConvention(select)}
                isClearable
              />
            </div>
          </div>
          <div className="details-bottom">
            <Button
              className="btn-round filter"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                onSave();
              }}
              size="lg"
            >
              Sauvegarder
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
