import React from "react";

// import DcFORM from "./dcForm";
import OthersFORM from "./othersForm";

const DependenciesForm = (props) => {
  return <OthersFORM {...props} />;
  // props.code === "g6" ? (
  //   <DcFORM {...props} />
  // ) : (
  //   <OthersFORM {...props} />
  // );
};

export default DependenciesForm;
