import { useState } from "react";
import { useDispatch } from "react-redux";
import { Api } from "api";

import { setGaranties, setExpandedGarantie } from "../actions";
import { garantiesUrl, garantieByIdUrl } from "../api/endpoints";

export function useFetchGaranties() {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const fetchGaranties = async (type, id) => {
    try {
      const data = await Api().get(garantiesUrl(type, id));
      dispatch(setGaranties(data));
    } catch (e) {
      setError(e);
      setFetching(false);
    }
  };

  return [{ fetching, error }, fetchGaranties];
}

export function useFetchOne() {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const fetchData = async (id) => {
    try {
      const data = await Api().get(garantieByIdUrl(id));
      dispatch(setExpandedGarantie(data));
    } catch (e) {
      setFetching(false);
    }
  };

  return [fetching, fetchData];
}
