// import front-end config
import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_FAILED,
  SET_CURRENT_USER,
  LOGOUT_SUCCESS,
  SET_SIDEBAR_OPEN,
  SET_ACTIVE_TAB,
} from "actionsTypes";

import { REHYDRATE } from "redux-persist/lib/constants";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  fetchingUser: false,
  currentUser: null,
  sidebarIsOpen: true,
  activeTab: "clients",
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      return { ...state, sidebarIsOpen: true };
    }

    case GET_CURRENT_USER_REQUEST:
      return { ...state, fetchingUser: true };

    case GET_CURRENT_USER_FAILED:
      return { ...state, fetchingUser: false };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        fetchingUser: false,
        isGuest: false,
      };

    case SET_SIDEBAR_OPEN:
      return { ...state, sidebarIsOpen: action.payload };

    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
