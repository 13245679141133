export function conventionsUrl(params) {
  return `/admin/conventions/?${params}&$sort={ "updated_at": "DESC"}`;
}

export function conventionByIdUrl(id) {
  return `/admin/conventions/${id}`;
}

export function createUrl() {
  return "/admin/conventions";
}

export function garantiesUrl(type, id) {
  return `/admin/${type}/${id}`;
}

export function garantieByIdUrl(id) {
  return `/admin/garanties/${id}`;
}

export function updateGarantieUrl(id) {
  return `/admin/garanties/${id}`;
}

export function createFormuleUrl() {
  return `/admin/formules`;
}

export function updateFormuleUrl(id) {
  return `/admin/formules/${id}`;
}

export function createContrainteUrl() {
  return `/admin/contraintes`;
}

export function updateContrainteUrl(id) {
  return `/admin/contraintes/${id}`;
}

export function createDepUrl() {
  return `/admin/interdependances`;
}

export function updateDepUrl(id) {
  return `/admin/interdependances/${id}`;
}

export function packByIdUrl(id, params) {
  return `/admin/packs/${id}?${params}`;
}

export function carteByIdUrl(id) {
  return `/admin/cartes/${id}`;
}
