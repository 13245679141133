import React from "react";
import Avatar from "@material-ui/core/Avatar";
import AppInput from "components/AppInput";
import ButtonTag from "components/ButtonTag";

const PackView = (props) => {
  const imageUrl =
    typeof props.image === "string"
      ? `${process.env.REACT_APP_DOMAIN}${props.image}`
      : null;
  const onSelectFile = (e) => {
    document.getElementById("pack-file-select").click();
  };

  return (
    <div className="pack-view">
      <div className="pack-left" onClick={props.onRemove}>
        <img
          alt="x"
          src={require("assets/img/rm-icon.png")}
          className="pack-remove"
        />
      </div>
      {props.image ? (
        <div className="photo-preview">
          <Avatar
            variant="rounded"
            alt="logo"
            src={props.image.url || imageUrl}
          />
          <img
            alt="x"
            src={require("assets/img/rm-icon.png")}
            className="pack-photo-remove"
            onClick={() => props.updateImage(null)}
          />
        </div>
      ) : (
        <>
          <ButtonTag size="xs" onClick={onSelectFile} label="Photo" />
          <input
            className="file-input-hidden"
            type="file"
            id="pack-file-select"
            onChange={(e) => {
              e.preventDefault();

              const file = e.target.files[0];
              const url = URL.createObjectURL(file);

              const reader = new FileReader();
              reader.onloadend = () => {
                props.updateImage({ file, url });
              };

              reader.readAsDataURL(file);
            }}
          />
        </>
      )}
      <AppInput
        size="lg"
        placeholder="Nom du pack"
        value={props.name}
        onChange={(t) => props.updateName(t)}
      />
    </div>
  );
};

export default PackView;
