import CONFIG from "config/apiConfig";

const { API_BASE_URL, API_VERSION } = CONFIG;
const baseURL = `${API_BASE_URL}${API_VERSION}`;

export default function downloadApi(url) {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseURL}${url}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/pdf",
          "Content-Type": "application/json",
          "Content-Disposition": "inline",
        },
        responseType: "blob",
      });
      const blob = await response.blob();
      const bNew = new Blob([blob], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(bNew);
      resolve(pdfUrl);
    } catch (e) {
      console.log("e", e);
      reject(e);
    }
  });

  return promise;
}
