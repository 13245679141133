import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router";

import routes from "./routes";

function Auth({ match, location }) {
  useEffect(() => {
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);
  return (
    <div className="page-header header-filter">
      <div
        className="page-header-image"
        style={{
          backgroundImage: "url(" + require("assets/img/login.png") + ")",
        }}
      ></div>
      <Switch>
        {routes.map((route, i) => {
          if (location.pathname === "/auth") {
            return <Redirect key={route.path} to="/auth/signin" />;
          }
          return (
            <Route
              key={i}
              path={`${match.path}${route.path}`}
              render={(props) => <route.component {...props} />}
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default Auth;
