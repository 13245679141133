import { combineReducers } from "redux";

/*
 * Import reducers
 */
import list from "./list";
import details from "./details";
import create from "./create";

export default combineReducers({
  list,
  details,
  create,
});
