// import front-end config
import {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
  FORGOT_PASS_FAILURE,
  RESET_FORGOT_FORM,
  CLEAR_AUTH_STATE,
} from "../actionsTypes";

import { SET_CURRENT_USER, LOGOUT_SUCCESS } from "actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  requestError: false,
  fetching: false,
  errorMessage: "",
  forgotState: "request",
  userEmail: "",
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_AUTH_STATE:
      return initialState;

    case SIGNIN_REQUEST:
      return {
        ...state,
        fetching: true,
        requestError: false,
        errorMessage: "",
      };

    case SIGNIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        requestError: false,
        errorMessage: "",
      };

    case SIGNIN_FAILURE:
      return {
        ...state,
        fetching: false,
        requestError: true,
        errorMessage: action.payload,
      };

    case SET_CURRENT_USER:
      return { ...state, fetching: false, requestError: false };

    case RESET_FORGOT_FORM:
      return {
        ...state,
        forgotState: "request",
        requestError: false,
        errorMessage: "",
      };

    case FORGOT_PASS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case FORGOT_PASS_SUCCESS:
      return {
        ...state,
        fetching: false,
        userEmail: action.payload,
        forgotState: "success",
      };

    case FORGOT_PASS_FAILURE:
      return {
        ...state,
        fetching: false,
        requestError: true,
        errorMessage: action.payload,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
