import { createSelector } from "reselect";

export const listStateSelect = createSelector(
  (state) => state.societes,
  (societes) => societes.list
);

export const detailsStateSelect = createSelector(
  (state) => state.societes,
  (societes) => societes.details
);

export const createStateSelect = createSelector(
  (state) => state.societes,
  (societes) => societes.create
);
