import { createSelector } from "reselect";

export const listStateSelect = createSelector(
  (state) => state.conventions,
  (conventions) => conventions.list
);

export const detailsStateSelect = createSelector(
  (state) => state.conventions,
  (conventions) => conventions.details
);

export const createStateSelect = createSelector(
  (state) => state.conventions,
  (conventions) => conventions.create
);

export const garantiesStateSelect = createSelector(
  (state) => state.conventions,
  (conventions) => conventions.garanties
);
