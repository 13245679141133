import React, { useState, useEffect } from "react";

import { DC_CONSTRAINTES } from "config/app";

import AppInput from "components/AppInput";
import AppDropDown from "components/AppDropDown";

const ConstraintesForm = ({ onFormChange, ...props }) => {
  const [initFactor, setInitFactor] = useState(null);
  const [constraint, setConstraint] = useState(null);
  const [state, setState] = useState(props.contrainte);
  const [contraintes, setContraintes] = useState(DC_CONSTRAINTES);

  useEffect(() => {
    const c = DC_CONSTRAINTES.find(
      (one) => one.value === props.contrainte.factor
    );
    if (c) {
      if (props.contrainte.percent) {
        setConstraint(DC_CONSTRAINTES[0]);
        setInitFactor(c);
      } else {
        setInitFactor(c);
        setConstraint(c);
      }
    }
  }, [props.contrainte.factor, props.contrainte.percent]);

  useEffect(() => {
    const factors = props.selected.map((one) => one.factor);
    setContraintes(
      DC_CONSTRAINTES.filter((one) => {
        const isPlafond = props.selected.find(
          (s) => s.percent && !s.min && !s.max
        );
        if (isPlafond && one.value === "PLAFOND") {
          return false;
        } else {
          return !factors.includes(one.value);
        }
      })
    );
  }, [props.selected]);

  useEffect(() => {
    onFormChange({
      ...state,
      factor:
        constraint?.value === "PLAFOND"
          ? state.factor?.value || state.factor
          : constraint?.value,
    });
  }, [constraint, onFormChange, state]);

  return (
    <div className="section-constraint-view">
      <div className="creation-element space-right">
        <h5>Type</h5>
        <AppDropDown
          selected={constraint}
          options={contraintes}
          setSelected={(value) => setConstraint(value)}
          size="xs"
        />
      </div>
      {constraint && constraint.value !== "PLAFOND" && (
        <>
          <div className="creation-element space-right">
            <h5>Minimum</h5>
            <AppInput
              size="lg"
              value={state.min}
              onChange={(t) =>
                setState({ ...state, min: t ? parseFloat(t) : "" })
              }
              type="number"
            />
          </div>
          <div className="creation-element space-right">
            <h5>Maximum</h5>
            <AppInput
              size="lg"
              value={state.max}
              onChange={(t) =>
                setState({ ...state, max: t ? parseFloat(t) : "" })
              }
              type="number"
            />
          </div>
        </>
      )}
      {constraint && constraint.value === "PLAFOND" && (
        <>
          <div className="creation-element space-right">
            <h5>Valeur de comparaison</h5>
            <AppDropDown
              selected={
                typeof state.factor === "string" ? initFactor : state.factor
              }
              options={[
                { label: "Valeur neuve", value: "VN" },
                { label: "Valeur vénale", value: "VV" },
              ]}
              setSelected={(value) => setState({ ...state, factor: value })}
              size="xs"
            />
          </div>
          <div className="creation-element space-right">
            <h5>Pourcentage</h5>
            <AppInput
              size="lg"
              value={state.percent}
              onChange={(t) =>
                setState({ ...state, percent: t ? parseFloat(t) : "" })
              }
              type="number"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ConstraintesForm;
