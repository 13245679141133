export function societesUrl(params) {
  return `/admin/employers/?${params}`;
}

export function societeByIdUrl(id) {
  return `/admin/employers/${id}`;
}

export function updateUrl(id) {
  return `/admin/employers/${id}`;
}

export function createUrl() {
  return `/admin/employers`;
}
