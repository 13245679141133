import React from "react";

// some plugins
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import { steps } from "./creationConfig";

const useCustomIcon = makeStyles({
  root: {
    backgroundColor: "#9AA2A8",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#1D9CD2",
  },
  completed: {
    backgroundColor: "#3CC47C",
  },
});

const StepIcon = (props) => {
  const classes = useCustomIcon();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {String(props.icon)}
    </div>
  );
};

const CreationStepper = ({ activeStep }) => {
  return (
    <Stepper alternativeLabel activeStep={activeStep}>
      {steps.map((step) => (
        <Step key={step.hash}>
          <StepLabel StepIconComponent={StepIcon}>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CreationStepper;
