import React from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

const SideBar = ({ isOpen, toggle, activeTab, setTab, displayLogout }) => (
  <div className={classNames("sidebar", { "is-open": isOpen })}>
    <div className="sidebar-header">
      <span color="info" onClick={toggle} style={{ color: "#000" }}>
        &times;
      </span>
      <div className="text-center">
        <img
          className="home-logo"
          alt="..."
          src={require("assets/img/logo_home.png")}
        ></img>
      </div>
    </div>
    <div className="side-menu">
      <Nav vertical className="list-unstyled pb-3">
        {/*<NavItem
          onClick={() => {
            if (window.innerWidth <= 800) {
              toggle();
            }
            setTab("home");
          }}
          className={classNames({
            "side-item-active": activeTab === "home",
          })}
        >
          <NavLink tag={Link} to={"/home"}>
            Accueil
          </NavLink>
        </NavItem>*/}
        <hr />
        <NavItem
          onClick={() => {
            if (window.innerWidth <= 800) {
              toggle();
            }
            setTab("societes");
          }}
          className={classNames({
            "side-item-active": activeTab === "societes",
          })}
        >
          <NavLink
            className={classNames({
              "side-item-active": activeTab === "societes",
            })}
            tag={Link}
            to={"/societes"}
          >
            Sociétés
          </NavLink>
        </NavItem>
        <hr />
        <NavItem
          onClick={() => {
            if (window.innerWidth <= 800) {
              toggle();
            }
            setTab("clients");
          }}
          className={classNames({
            "side-item-active": activeTab === "clients",
          })}
        >
          <NavLink
            className={classNames({
              "side-item-active": activeTab === "clients",
            })}
            tag={Link}
            to={"/clients"}
          >
            Clients
          </NavLink>
        </NavItem>
        <hr />
        <NavItem
          onClick={() => {
            if (window.innerWidth <= 800) {
              toggle();
            }
            setTab("conventions");
          }}
          className={classNames({
            "side-item-active": activeTab === "conventions",
          })}
        >
          <NavLink
            className={classNames({
              "side-item-active": activeTab === "conventions",
            })}
            tag={Link}
            to={"/conventions"}
          >
            Conventions
          </NavLink>
        </NavItem>
        <hr />
        <NavItem
          onClick={() => {
            if (window.innerWidth <= 800) {
              toggle();
            }
            setTab("requests");
          }}
          className={classNames({
            "side-item-active": activeTab === "requests",
          })}
        >
          <NavLink
            className={classNames({
              "side-item-active": activeTab === "requests",
            })}
            tag={Link}
            to={"/requests"}
          >
            Demandes
          </NavLink>
        </NavItem>
        <hr />
      </Nav>
    </div>
    <div
      tag="button"
      onClick={(e) => {
        displayLogout();
      }}
      className="sidebar-footer"
    >
      <div className="text-disconnect">Se déconnecter</div>
    </div>
  </div>
);

export default SideBar;
