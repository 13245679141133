import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";

// some plugins
import AsyncSelect from "react-select/async";

// some config variables
import downloadApi from "api/downloadApi";
import { downloadFile } from "lib/helpers";

import PageDescription from "components/PageDescription";
import CustomTable from "components/CustomTable";
import EmptyList from "components/EmptyList";

import useFetchList from "hooks/useFetchList";
import { Api } from "api";
import { clientsUrl } from "../../api/endpoints";
import * as tableConfig from "./tableConfig";

import "assets/css/listviews.css";

export default function ClientsList(props) {
  const [fetchParams, setFecthParams] = useState({ skip: 0, top: 10 });
  const [apiEndpoint, setEndpoint] = useState(clientsUrl(""));
  const { data, count, fetching } = useFetchList(apiEndpoint);

  useEffect(() => {
    const filter = fetchParams.employer
      ? JSON.stringify({ employer: fetchParams.employer })
      : "";
    const urlQuery = `$skip=${fetchParams.skip}&$top=${fetchParams.top}${
      fetchParams.q ? "&$q=" + fetchParams.q : ""
    }${filter ? "&$filter=" + filter : ""}`;
    setEndpoint(clientsUrl(urlQuery));
  }, [fetchParams]);

  useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });

  const loadOptions = (inputValue) =>
    new Promise(async (resolve) => {
      try {
        const filter = inputValue ? `$filter={"name": "${inputValue}"}` : "";
        const data = await Api().get(`/employers/?${filter}`);
        const formatted = data.value.map((one) => ({
          value: one._id,
          label: one.name,
        }));
        resolve(formatted);
      } catch {
        resolve([]);
      }
    });

  const exportCsv = async () => {
    try {
      const url = await downloadApi("/admin/users/excel");
      downloadFile(url, "clients.xlsx");
    } catch (e) {}
  };

  return (
    <div className="app-main">
      <PageDescription
        icon={require("assets/img/heart.png")}
        title="Base de Clients"
      />
      <div className="list-top-view">
        <span className="text-bold export-btn" tag="button" onClick={exportCsv}>
          Exporter en CSV
        </span>
      </div>
      <div className="filters-view">
        <Input
          placeholder="Chercher par nom"
          type="text"
          name="filter-name"
          className={"filter-input"}
          value={fetchParams.q}
          onChange={(e) => {
            e.preventDefault();
            setFecthParams({
              q: e.currentTarget.value,
              skip: 0,
              top: fetchParams.top,
            });
          }}
        ></Input>
        <AsyncSelect
          placeholder="Employeur"
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          className="react-select react-select-primary filter-input"
          classNamePrefix="react-select"
          onChange={(select) =>
            setFecthParams({ ...fetchParams, employer: select?.value })
          }
          isClearable
        />
      </div>
      <div>
        {data.length === 0 ? (
          <EmptyList fetching={fetching} text={"Aucun client trouvé"} />
        ) : (
          <CustomTable
            columns={tableConfig.columns}
            dataSource={data}
            cellKey={"id"}
            onRowPress={(item) => {
              props.actions.setCurrentDoc(item);
              props.history.push("/clients/details");
            }}
            pagination={{
              count,
              rowsPerPage: fetchParams.top,
              page: Math.round(fetchParams.skip / fetchParams.top),
            }}
            handleChangePage={(params) => {
              setFecthParams(params);
            }}
            handleChangeRowsPerPage={(params) => {
              setFecthParams(params);
            }}
            rowClickable
          />
        )}
      </div>
    </div>
  );
}
