import React from "react";
import moment from "lib/moment";

import StatusTag from "components/StatusTag";
// import ApiConfig from "config/apiConfig";

export const columns = [
  {
    title: "N°",
    dataIndex: "number",
    renderItem: (info, props) => <span>{info.number}</span>,
  },
  {
    title: "Nom de la convention",
    dataIndex: "name",
    renderItem: (info, props) => <span>{info.name}</span>,
  },
  {
    title: "Date de création",
    dataIndex: "createdAt",
    renderItem: (info, props) => (
      <span>{moment(info.createdAt).format("L")}</span>
    ),
  },
  {
    title: "Statut",
    dataIndex: "isEnabled",
    renderItem: (info, props) => (
      <StatusTag
        label={info.isEnabled ? "Active" : "Désactivée"}
        status={`tag-${info.isEnabled ? "active" : "desactive"}`}
      />
    ),
  },
  {
    title: "",
    dataIndex: "none",
    renderItem: () => {
      return (
        <div className="text-right">
          <img
            src={require("assets/img/chevron-right.png")}
            className="chevron-right"
            alt=">"
          />
        </div>
      );
    },
  },
];
