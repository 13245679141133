import React from "react";
// import { useSelector } from "react-redux";
import { Navbar } from "reactstrap";
import classNames from "classnames";

// import { currentUserSelect } from "../selector";

const TopBar = (props) => {
  const { toggle, sidebarIsOpen, breadCrumbs /*, displayLogout*/ } = props;
  // const currentUser = useSelector(currentUserSelect);
  return (
    <Navbar
      className={classNames("navbar-transparent fixed-bar", {
        "is-sidebar-open": sidebarIsOpen,
      })}
      expand="lg"
    >
      <div className="navbar-translate display-row">
        <div className="breadcrumbs-container">
          <button
            onClick={() => {
              toggle(!sidebarIsOpen);
            }}
            aria-expanded={sidebarIsOpen}
            className="navbar-toggler"
          >
            <span className="navbar-toggler-bar top-bar"></span>
            <span className="navbar-toggler-bar middle-bar"></span>
            <span className="navbar-toggler-bar bottom-bar"></span>
          </button>
          {breadCrumbs && breadCrumbs.display && <breadCrumbs.component />}
        </div>
        {/*<div className="topbar-right">
          <span className="topbar-text">{`Bienvenue ${currentUser.FULL_NAME}`}</span>
          <div
            tag="button"
            onClick={(e) => {
              displayLogout();
            }}
            className="topbar-disconnect"
          >
            <div className="text-center topbar-text top-deconnect">
              Se déconnecter
            </div>
          </div>
          </div>*/}
      </div>
    </Navbar>
  );
};

export default TopBar;
