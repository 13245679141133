import React, { useState } from "react";
import { Button } from "reactstrap";

import { downloadFile } from "lib/helpers";

import { useDownloadPdf } from "hooks/useDownloadPdf";

export default function Recap(props) {
  const [pdfUrl, setPdfUrl] = useState("");
  const { fetching, data } = useDownloadPdf(
    `/requests/${props.devis?._id}/report`,
    setPdfUrl
  );
  const onSubmit = () => {
    downloadFile(pdfUrl);
  };

  return (
    <div className="step-container">
      <div>
        <iframe
          id="pdf-preview"
          title="Devis Report"
          src={pdfUrl}
          type="application/pdf"
          width="100%"
          height="400px"
        ></iframe>
        <div className="text-center">
          <Button
            className="btn-round"
            color="default"
            onClick={(e) => {
              onSubmit();
            }}
            size="lg"
          >
            Imprimer
          </Button>
        </div>
      </div>
    </div>
  );
}
