export function meUrl() {
  return "/me";
}

export function logoutUrl() {
  return "/auth/admins/signout";
}

export function uploadUrl() {
  return "/files";
}
