import React from "react";

import SectionHeader from "./sectionHeader";
import SectionContent from "./sectionContent";

const Section = ({
  garantie,
  onExpand,
  expanded,
  expandedValue,
  actions,
  activeSection,
  carte,
  enabled,
  readonly,
  fetching,
  ...props
}) => {
  return (
    <div className="garantie-view" key={garantie?.code}>
      <SectionHeader
        onExpand={onExpand}
        expanded={expanded}
        garantie={garantie}
        actions={actions}
        enabled={enabled}
      />
      {expanded && (
        <SectionContent
          carte={carte}
          garantie={expandedValue || garantie}
          actions={actions}
          readonly={readonly}
          fetching={fetching}
        />
      )}
    </div>
  );
};

export default Section;
