import homeRoutes from "modules/Home/routes";
import Clients from "modules/Clients";
import Societes from "modules/Societes";
import Conventions from "modules/Conventions";
import Demandes from "modules/Demandes";

const routes = [
  ...homeRoutes,
  { path: "/clients", component: Clients },
  { path: "/societes", component: Societes },
  { path: "/conventions", component: Conventions },
  { path: "/requests", component: Demandes },
];

export default routes;
