import { combineReducers } from "redux";

/*
 * Import reducers
 */
import list from "./list";
import details from "./details";

export default combineReducers({
  list,
  details,
});
