import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";

export const steps = [
  {
    label: "Informations Générales",
    hash: "#general",
    index: 0,
    component: Step1,
  },
  { label: "Garanties", hash: "#paramaters", index: 1, component: Step2 },
  { label: "Assistance", hash: "#assistance", index: 2, component: Step3 },
  { label: "Recapitulatif", hash: "#recap", index: 3, component: Step4 },
];
