import React from "react";
import { Row, Col, Button } from "reactstrap";

const Step3 = (props) => {
  const { editing } = props.create;
  return (
    <>
      <div className="conv-tab-content text-center">
        {!editing && (
          <h3 className="page-title-text">
            Vous êtes sur le point de créer cette convention
          </h3>
        )}
        <img alt="ok" src={require("assets/img/ok.png")} className="ok-img" />
      </div>
      <div className="creation-footer">
        <Row>
          <Col xs="12" sm="4" className="footer-btn">
            <Button
              className="btn-round"
              color="primary"
              onClick={(e) => {
                props.history.goBack();
              }}
              size="lg"
            >
              Précédent
            </Button>
          </Col>

          <Col xs="12" sm="4" className="footer-btn">
            <Button
              className="btn-round"
              onClick={(e) => {
                props.actions.clearCreationForm();
                props.history.push("/conventions");
              }}
              size="lg"
              color="primary"
            >
              Sauvegarder et quitter
            </Button>
          </Col>
          <Col xs="12" sm="4" className="footer-btn">
            <Button
              className="btn-round"
              color="secondary"
              onClick={(e) => {
                props.actions.clearCreationForm();
                props.history.push("/conventions");
                props.actions.updateConvention(props.create.current?._id, {
                  isEnabled: true,
                });
                // should activate
              }}
              size="lg"
            >
              Sauvegarder et activer
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Step3;
