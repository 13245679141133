import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import { createStateSelect, garantiesStateSelect } from "../../selector";

import GarantiesView from "./component";

const GarantiesViewContainer = (props) => {
  const create = useSelector(createStateSelect);
  const garanties = useSelector(garantiesStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <GarantiesView
      garanties={garanties}
      create={create}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default GarantiesViewContainer;
