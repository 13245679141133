import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Spinner,
} from "reactstrap";

const RequestForm = (props) => {
  const [username, setUsername] = useState("");

  const onChangeInput = (e) => {
    e.preventDefault();
    setUsername(e.currentTarget.value);
  };

  const onSendRequest = (e) => {
    e.preventDefault();
    props.actions.forgotPassword({ username });
  };

  return (
    <Container>
      <Row>
        <Col sm={{ size: 6, offset: 3 }} className="login-form">
          <div className="auth-logo-container">
            <img
              className="login-logo"
              alt="..."
              src={require("assets/img/login_logo.png")}
            ></img>
          </div>
          <Card className="card-login card-plain">
            <Form action="" className="form" method="">
              <CardBody>
                <InputGroup className={"input-lg"}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className={"bg-input"}>
                      <img
                        alt="..."
                        className="input-icon"
                        src={require("assets/img/cin.png")}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Login"
                    type="text"
                    name="userCin"
                    className="login-input bg-input"
                    value={username}
                    onChange={(e) => onChangeInput(e)}
                  ></Input>
                </InputGroup>
                <div className="card-footer-buttons">
                  {props.auth.requestError && (
                    <h6 className="contact-info">{props.auth.errorMessage}</h6>
                  )}
                  <Button
                    block
                    className="btn-round btn-login"
                    color="neutral"
                    onClick={(e) => onSendRequest(e)}
                    size="lg"
                    disabled={props.auth.fetching || !username}
                  >
                    {props.auth.fetching ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Récupérer le mot de passe"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RequestForm;
