export function loginUrl() {
  return "/auth/admins/signin";
}

export function forgotUrl() {
  return "/auth/admins/forgot";
}

export function signupUrl() {
  return "/auth/signup";
}

export function resetPassUrl() {
  return "/auth/admins/password";
}

export function logoutUrl() {
  return "/auth/admins/signout";
}
