export const SET_CURRENT_CONV = "SET_CURRENT_CONV";

export const SET_CREATED_CONV = "SET_CREATED_CONV";
export const CLEAR_CREATION_FORM = "CLEAR_CREATION_FORM";
export const UPDATE_CREATION_FORM = "UPDATE_CREATION_FORM";

export const SET_CONV_GARANTIES = "SET_CONV_GARANTIES";
export const SET_EXPANDED_GARANTIE = "SET_EXPANDED_GARANTIE";
export const UPDATE_EXPANDED_GARANTIE = "UPDATE_EXPANDED_GARANTIE";

export const UPDATE_CURRENT_PACK = "UPDATE_CURRENT_PACK";
