// declare actions types
import {
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILED,
  SET_CURRENT_CLIENT,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
} from "../actionsTypes";

import { LOGOUT_SUCCESS } from "actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  current: null,
  fetching: false,
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function details(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CLIENT:
      return { ...state, current: action.payload };

    case GET_CLIENT_REQUEST:
      return { ...state, fetching: true };

    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        current: action.payload,
      };

    case GET_CLIENT_FAILED:
      return { ...state, fetching: false };

    case UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        current: {
          ...state.current,
          accountEnabled: action.payload.accountEnabled,
        },
      };

    case UPDATE_CLIENT_FAILED:
      return {
        ...state,
        fetching: false,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
