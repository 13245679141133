import React from "react";

const Back = ({ history }) => {
  const onBack = () => {
    history.goBack();
  };

  return (
    <div tag="button" onClick={onBack} className="btn-back">
      <img
        alt="back"
        className="back-icon"
        src={require("assets/img/arrow-left-white.png")}
      />
    </div>
  );
};

export default Back;
