import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Alert, Button, Spinner } from "reactstrap";

import { FORMULE, CONTRAINTE, DEPENDANCE } from "config/app";

import ButtonTag from "components/ButtonTag";
import FormuleForm from "../forms/formuleForms";
import DependenciesForm from "../forms/dependenciesForms";
import ConstraintesForm from "../forms/constraintsForms";

import { useUpdateGarantie } from "../../../hooks/useUpdateGarantie";

const SectionContent = (props) => {
  const {
    code,
    formules: gFormules,
    contraintes: gConstraints,
    interdependances,
    _id: gId,
  } = props.garantie;
  const [formules, setFormules] = useState([]);
  const [constraints, setConstraints] = useState([]);
  const [dependencies, setDependencies] = useState([]);

  const [{ fetching, error }, updateGarantie] = useUpdateGarantie();

  useEffect(() => {
    setFormules(gFormules || []);
    setConstraints(gConstraints || []);
    setDependencies(interdependances || []);
  }, [gFormules, gConstraints, interdependances]);

  const addNewFormule = () => {
    setFormules(
      formules.concat([{ ...FORMULE, num: Math.random(), garantie: gId }])
    );
  };

  const onRemoveFormule = (i, id) => {
    formules.splice(i, 1);
    setFormules([...formules]);
    if (id) {
      props.actions.removeFormule(id);
    }
  };

  const addNewConstraint = () => {
    setConstraints(
      constraints.concat([
        {
          ...CONTRAINTE,
          num: Math.random(),
          garantie: gId,
          carte: props.carte,
        },
      ])
    );
  };

  const onRemoveConstraint = (i, id) => {
    constraints.splice(i, 1);
    setConstraints([...constraints]);
    if (id) {
      props.actions.removeContrainte(id);
    }
  };

  const addNewDep = () => {
    setDependencies(
      dependencies.concat([
        { ...DEPENDANCE, num: Math.random(), garantie: gId },
      ])
    );
  };

  const onRemoveDependencie = (i, id) => {
    dependencies.splice(i, 1);
    setDependencies([...dependencies]);
    if (id) {
      props.actions.removeDependance(id);
    }
  };

  const onUpdate = () => {
    updateGarantie({
      formules,
      contraintes: constraints,
      interdependances: dependencies,
    });
  };

  const renderFormules = () => {
    return (
      <div className="formule-view">
        <span>Formules </span>
        {formules.map((f, i) => (
          <div key={f._id || f.num.toString()} className="formule-view-item">
            <div
              onClick={() => onRemoveFormule(i, f._id)}
              className="formule-rm"
            >
              <img
                alt="x"
                src={require("assets/img/rm-icon.png")}
                className="rm-icon"
              />
            </div>
            <div style={{ flex: 1 }}>
              <span className="formule-title">{`Formule ${i + 1}`}</span>
              <FormuleForm
                key={f._id || f.num.toString()}
                withTarif={!props.readonly}
                code={code}
                formule={f}
                onFormChange={(form) => {
                  const old = formules;
                  old.splice(i, 1, form);
                  setFormules(old);
                }}
              />
            </div>
          </div>
        ))}
        {!props.readonly ? (
          <ButtonTag
            onClick={addNewFormule}
            label="Ajouter une formule"
            active
          />
        ) : null}
      </div>
    );
  };

  const renderConstraints = () => {
    return (
      <div className="constraints-view">
        <span>Contraintes</span>
        {constraints.map((c, i) => (
          <div key={c._id || c.num.toString()} className="formule-view-item">
            <div style={{ flex: 1 }}>
              <ConstraintesForm
                key={c._id || c.num.toString()}
                code={code}
                contrainte={c}
                selected={constraints}
                onFormChange={(form) => {
                  const old = constraints;
                  old.splice(i, 1, form);
                  setConstraints(old);
                }}
              />
            </div>
            <div
              onClick={() => onRemoveConstraint(i, c._id)}
              className="deps-rm"
            >
              <img
                alt="x"
                src={require("assets/img/rm-icon.png")}
                className="rm-icon"
              />
            </div>
          </div>
        ))}
        {!props.readonly ? (
          <ButtonTag
            onClick={addNewConstraint}
            label="Ajouter une contrainte"
            active
          />
        ) : null}
      </div>
    );
  };

  const renderDeps = () => {
    return (
      <div className="dependencies-view">
        <span>Interdépendances</span>
        {dependencies.map((dep, i) => (
          <div
            key={dep._id || dep.num.toString()}
            className="formule-view-item"
          >
            <div style={{ flex: 1 }}>
              <DependenciesForm
                key={dep._id || dep.num.toString()}
                code={code}
                dependance={dep}
                readonly={props.readonly}
                onFormChange={(form) => {
                  const old = dependencies;
                  old.splice(i, 1, form);
                  setDependencies(old);
                }}
              />
            </div>
            <div
              onClick={() => onRemoveDependencie(i, dep._id)}
              className="deps-rm"
            >
              <img
                alt="x"
                src={require("assets/img/rm-icon.png")}
                className="rm-icon"
              />
            </div>
          </div>
        ))}
        {!props.readonly ? (
          <ButtonTag
            onClick={addNewDep}
            label="Ajouter une interdépendance"
            active
          />
        ) : null}
      </div>
    );
  };

  if (props.fetching) {
    return (
      <div className="section-content-view">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={classNames("section-content-view", {
        readonly: props.readonly,
      })}
    >
      {renderFormules()}

      {renderConstraints()}

      {renderDeps()}

      <Button
        className="btn-round"
        color="primary"
        onClick={(e) => {
          onUpdate();
        }}
        size="lg"
      >
        {fetching ? (
          <Spinner size="sm" color="light" />
        ) : (
          "Sauvegarder cette garantie"
        )}
      </Button>
      {props.readonly && <div className="readonly-mask" />}
      {error ? (
        <Alert color="danger">
          {error.message || "Un erreur est survenue. Veuillez réessayer"}
        </Alert>
      ) : null}
    </div>
  );
};

export default SectionContent;
