import React, { useEffect, useState } from "react";
import { Input, Button } from "reactstrap";

// some plugins

// some config variables
import downloadApi from "api/downloadApi";
import { downloadFile } from "lib/helpers";

import PageDescription from "components/PageDescription";
import CustomTable from "components/CustomTable";
import EmptyList from "components/EmptyList";

import useFetchList from "hooks/useFetchList";
import { societesUrl } from "../../api/endpoints";
import * as tableConfig from "./tableConfig";

import "assets/css/listviews.css";

export default function EmployeursList(props) {
  const [fetchParams, setFecthParams] = useState({ skip: 0, top: 10 });
  const [apiEndpoint, setEndpoint] = useState(societesUrl(""));
  const { data, count, fetching } = useFetchList(apiEndpoint);

  useEffect(() => {
    const urlQuery = `$skip=${fetchParams.skip}&$top=${fetchParams.top}${
      fetchParams.q ? "&$q=" + fetchParams.q : ""
    }`;
    setEndpoint(societesUrl(urlQuery));
  }, [fetchParams]);

  useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });

  const exportCsv = async () => {
    try {
      const url = await downloadApi("/me/cd");
      downloadFile(url, "societes.xlsx");
    } catch (e) {}
  };

  return (
    <div className="app-main">
      <PageDescription
        icon={require("assets/img/heart.png")}
        title="Base de Sociétés"
      />
      <div className="list-top-view">
        <span className="text-bold export-btn" tag="button" onClick={exportCsv}>
          Exporter en CSV
        </span>
      </div>
      <div className="filters-view societe-list">
        <Button
          className="btn-round filter"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.history.push("/societes/create");
          }}
          size="lg"
        >
          Nouvelle société
        </Button>
        <Input
          placeholder="Chercher par nom ou convention"
          type="text"
          name="filter-name"
          className={"filter-input"}
          value={fetchParams.q}
          onChange={(e) => {
            e.preventDefault();
            setFecthParams({
              q: e.currentTarget.value,
              skip: 0,
              top: fetchParams.top,
            });
          }}
        ></Input>
      </div>
      <div>
        {data.length === 0 ? (
          <EmptyList fetching={fetching} text={"Aucune société trouvée"} />
        ) : (
          <CustomTable
            columns={tableConfig.columns}
            dataSource={data}
            cellKey={"id"}
            onRowPress={(item) => {
              props.actions.setCurrentDoc(item);
              props.history.push("/societes/details");
            }}
            pagination={{
              count,
              rowsPerPage: fetchParams.top,
              page: Math.round(fetchParams.skip / fetchParams.top),
            }}
            handleChangePage={(params) => {
              setFecthParams(params);
            }}
            handleChangeRowsPerPage={(params) => {
              setFecthParams(params);
            }}
            rowClickable
          />
        )}
      </div>
    </div>
  );
}
