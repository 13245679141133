import React, { useEffect, useState } from "react";

const SectionHeader = (props) => {
  const [enabled, setEnabled] = useState(props.enabled);
  useEffect(() => {
    setEnabled(props.enabled);
  }, [props.enabled]);

  return (
    <div className="section-header">
      <div onClick={props.onExpand} className="section-header-content">
        <span>{`${props.garantie.code.replace("g", "")}. ${
          props.garantie.name
        }`}</span>
      </div>
      <div className="section-side">
        <div
          onClick={() => {
            if (!props.garantie.enabledByDefault) {
              // update to BE
              const newV = !enabled;
              setEnabled(newV);
              props.actions.setEnabled(props.garantie._id, {
                isEnabled: newV,
              });
            }
          }}
          className="checkmark-view"
        >
          <img
            alt="-"
            src={
              enabled
                ? require("assets/img/checkValid.png")
                : require("assets/img/checkEmpty.png")
            }
            className="checkmark"
          />
        </div>
        <img
          alt="v"
          src={
            props.expanded
              ? require("assets/img/arrow-up.png")
              : require("assets/img/arrow-down.png")
          }
          className="arrow-expansion"
          onClick={props.onExpand}
        />
      </div>
    </div>
  );
};

export default SectionHeader;
