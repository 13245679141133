import React from "react";

import { Row, Col, Button, Spinner } from "reactstrap";

const StepFooter = ({ previous, nextDisabled, next, fetching, ...props }) => {
  return (
    <div className="creation-footer">
      <Row>
        {previous ? (
          <Col xs="12" sm="4" className="footer-btn">
            <Button
              className="btn-round"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
              }}
              size="lg"
            >
              Précédent
            </Button>
          </Col>
        ) : (
          <Col xs="12" sm="4" className="footer-btn"></Col>
        )}
        <Col xs="12" sm="4" className="footer-btn">
          <Button
            className="btn-round"
            onClick={(e) => {
              props.onCancel();
            }}
            size="lg"
            color="default"
            outline
            disabled={fetching}
          >
            Annuler
          </Button>
        </Col>
        <Col xs="12" sm="4" className="footer-btn">
          <Button
            className="btn-round"
            color="secondary"
            onClick={(e) => {
              // e.preventDefault();
              if (props.onPressNext) {
                props.onPressNext();
              } else {
                props.history.push(next);
              }
            }}
            size="lg"
            disabled={nextDisabled || fetching}
          >
            {fetching ? <Spinner size="sm" color="light" /> : "Continuer"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

StepFooter.defaultProps = {
  previous: true,
};

export default StepFooter;
