import React, { useState, useEffect } from "react";

import { ASSIETTES_TARIF } from "config/app";

import AppInput from "components/AppInput";
import AppDropDown from "components/AppDropDown";

const FormuleForm = (props) => {
  const [initAssietes, setInitAssietes] = useState({
    assiete_ess_6cv: null,
    assiete_ess_7_8cv: null,
    assiete_ess_9_10cv: null,
    assiete_ess_10cv: null,
    assiete_gas_4cv: null,
    assiete_gas_5cv: null,
    assiete_gas_6_7cv: null,
    assiete_gas_8cv: null,
  });
  const [state, setState] = useState(props.formule);

  useEffect(() => {
    const {
      tarif_ess_6cv,
      tarif_ess_7_8cv,
      tarif_ess_9_10cv,
      tarif_ess_10cv,
      tarif_gas_4cv,
      tarif_gas_5cv,
      tarif_gas_6_7cv,
      tarif_gas_8cv,
    } = props.formule;
    const cv_6 = ASSIETTES_TARIF.find(
      (one) => one.value === tarif_ess_6cv.assiete
    );
    const cv_7_8 = ASSIETTES_TARIF.find(
      (one) => one.value === tarif_ess_7_8cv.assiete
    );
    const cv_9_10 = ASSIETTES_TARIF.find(
      (one) => one.value === tarif_ess_9_10cv.assiete
    );
    const cv_10 = ASSIETTES_TARIF.find(
      (one) => one.value === tarif_ess_10cv.assiete
    );
    const gas_4cv = ASSIETTES_TARIF.find(
      (one) => one.value === tarif_gas_4cv.assiete
    );
    const gas_5cv = ASSIETTES_TARIF.find(
      (one) => one.value === tarif_gas_5cv.assiete
    );
    const gas_6_7cv = ASSIETTES_TARIF.find(
      (one) => one.value === tarif_gas_6_7cv.assiete
    );
    const gas_8cv = ASSIETTES_TARIF.find(
      (one) => one.value === tarif_gas_8cv.assiete
    );

    if (
      cv_6 ||
      cv_7_8 ||
      cv_9_10 ||
      cv_10 ||
      gas_4cv ||
      gas_5cv ||
      gas_6_7cv ||
      gas_8cv
    ) {
      setInitAssietes({
        ...(cv_6 && { assiete_ess_6cv: cv_6 }),
        ...(cv_7_8 && { assiete_ess_7_8cv: cv_7_8 }),
        ...(cv_9_10 && { assiete_ess_9_10cv: cv_9_10 }),
        ...(cv_10 && { assiete_ess_10cv: cv_10 }),
        ...(gas_4cv && { assiete_gas_4cv: gas_4cv }),
        ...(gas_5cv && { assiete_gas_5cv: gas_5cv }),
        ...(gas_6_7cv && { assiete_gas_6_7cv: gas_6_7cv }),
        ...(gas_8cv && { assiete_gas_8cv: gas_8cv }),
      });
    }
  }, [props.formule]);

  const { onFormChange } = props;

  useEffect(() => {
    onFormChange({
      ...state,
      isG6: true,
      desc_plafond_type: "TXT",
      tarif_ess_6cv: {
        ...state.tarif_ess_6cv,
        tarif_ht: state.tarif_ess_6cv.tarif_ht
          ? state.tarif_ess_6cv.tarif_ht
          : 0,
        prim_ht: state.tarif_ess_6cv.prim_ht ? state.tarif_ess_6cv.prim_ht : 0,
        assiete:
          state.tarif_ess_6cv.assiete.value || state.tarif_ess_6cv.assiete,
      },
      tarif_ess_7_8cv: {
        ...state.tarif_ess_7_8cv,
        tarif_ht: state.tarif_ess_7_8cv.tarif_ht
          ? state.tarif_ess_7_8cv.tarif_ht
          : 0,
        prim_ht: state.tarif_ess_7_8cv.prim_ht
          ? state.tarif_ess_7_8cv.prim_ht
          : 0,
        assiete:
          state.tarif_ess_7_8cv.assiete.value || state.tarif_ess_7_8cv.assiete,
      },
      tarif_ess_9_10cv: {
        ...state.tarif_ess_9_10cv,
        tarif_ht: state.tarif_ess_9_10cv.tarif_ht
          ? state.tarif_ess_9_10cv.tarif_ht
          : 0,
        prim_ht: state.tarif_ess_9_10cv.prim_ht
          ? state.tarif_ess_9_10cv.prim_ht
          : 0,
        assiete:
          state.tarif_ess_9_10cv.assiete.value ||
          state.tarif_ess_9_10cv.assiete,
      },
      tarif_ess_10cv: {
        ...state.tarif_ess_10cv,
        tarif_ht: state.tarif_ess_10cv.tarif_ht
          ? state.tarif_ess_10cv.tarif_ht
          : 0,
        prim_ht: state.tarif_ess_10cv.prim_ht
          ? state.tarif_ess_10cv.prim_ht
          : 0,
        assiete:
          state.tarif_ess_10cv.assiete.value || state.tarif_ess_10cv.assiete,
      },
      tarif_gas_4cv: {
        ...state.tarif_gas_4cv,
        tarif_ht: state.tarif_gas_4cv.tarif_ht
          ? state.tarif_gas_4cv.tarif_ht
          : 0,
        prim_ht: state.tarif_gas_4cv.prim_ht ? state.tarif_gas_4cv.prim_ht : 0,
        assiete:
          state.tarif_gas_4cv.assiete.value || state.tarif_gas_4cv.assiete,
      },
      tarif_gas_5cv: {
        ...state.tarif_gas_5cv,
        tarif_ht: state.tarif_gas_5cv.tarif_ht
          ? state.tarif_gas_5cv.tarif_ht
          : 0,
        prim_ht: state.tarif_gas_5cv.prim_ht ? state.tarif_gas_5cv.prim_ht : 0,
        assiete:
          state.tarif_gas_5cv.assiete.value || state.tarif_gas_5cv.assiete,
      },
      tarif_gas_6_7cv: {
        ...state.tarif_gas_6_7cv,
        tarif_ht: state.tarif_gas_6_7cv.tarif_ht
          ? state.tarif_gas_6_7cv.tarif_ht
          : 0,
        prim_ht: state.tarif_gas_6_7cv.prim_ht
          ? state.tarif_gas_6_7cv.prim_ht
          : 0,
        assiete:
          state.tarif_gas_6_7cv.assiete.value || state.tarif_gas_6_7cv.assiete,
      },
      tarif_gas_8cv: {
        ...state.tarif_gas_8cv,
        tarif_ht: state.tarif_gas_8cv.tarif_ht
          ? state.tarif_gas_8cv.tarif_ht
          : 0,
        prim_ht: state.tarif_gas_8cv.prim_ht ? state.tarif_gas_8cv.prim_ht : 0,
        assiete:
          state.tarif_gas_8cv.assiete.value || state.tarif_gas_8cv.assiete,
      },
    });
  }, [onFormChange, state]);

  const renderTarifs = () => (
    <>
      <div className="dc-tarifs-container">
        <h5> Essence </h5>
        <div className="creation-element">
          <h5>{"PF <= 6 CV"}</h5>
          <div className="tarifs-view">
            <div className="creation-element space-right">
              <h5>Tarif HT (%)</h5>
              <AppInput
                size="lg"
                value={state.tarif_ess_6cv.tarif_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_ess_6cv: {
                      ...state.tarif_ess_6cv,
                      tarif_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Prime HT minimun </h5>
              <AppInput
                size="lg"
                value={state.tarif_ess_6cv.prim_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_ess_6cv: {
                      ...state.tarif_ess_6cv,
                      prim_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Assiette d'application du % de tarif</h5>
              <AppDropDown
                size="xs"
                selected={
                  typeof state.tarif_ess_6cv.assiete === "string"
                    ? initAssietes.assiete_ess_6cv
                    : state.tarif_ess_6cv.assiete
                }
                options={ASSIETTES_TARIF}
                setSelected={(value) =>
                  setState({
                    ...state,
                    tarif_ess_6cv: { ...state.tarif_ess_6cv, assiete: value },
                  })
                }
              />
            </div>
          </div>
          <h5>{"PF = 7 ou 8 CV"}</h5>
          <div className="tarifs-view">
            <div className="creation-element space-right">
              <h5>Tarif HT (%)</h5>
              <AppInput
                size="lg"
                value={state.tarif_ess_7_8cv.tarif_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_ess_7_8cv: {
                      ...state.tarif_ess_7_8cv,
                      tarif_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Prime HT minimun </h5>
              <AppInput
                size="lg"
                value={state.tarif_ess_7_8cv.prim_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_ess_7_8cv: {
                      ...state.tarif_ess_7_8cv,
                      prim_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Assiette d'application du % de tarif</h5>
              <AppDropDown
                size="xs"
                selected={
                  typeof state.tarif_ess_7_8cv.assiete === "string"
                    ? initAssietes.assiete_ess_7_8cv
                    : state.tarif_ess_7_8cv.assiete
                }
                options={ASSIETTES_TARIF}
                setSelected={(value) =>
                  setState({
                    ...state,
                    tarif_ess_7_8cv: {
                      ...state.tarif_ess_7_8cv,
                      assiete: value,
                    },
                  })
                }
                type="number"
              />
            </div>
          </div>
          <h5>{"PF = 9 ou 10 CV"}</h5>
          <div className="tarifs-view">
            <div className="creation-element space-right">
              <h5>Tarif HT (%)</h5>
              <AppInput
                size="lg"
                value={state.tarif_ess_9_10cv.tarif_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_ess_9_10cv: {
                      ...state.tarif_ess_9_10cv,
                      tarif_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Prime HT minimun </h5>
              <AppInput
                size="lg"
                value={state.tarif_ess_9_10cv.prim_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_ess_9_10cv: {
                      ...state.tarif_ess_9_10cv,
                      prim_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Assiette d'application du % de tarif</h5>
              <AppDropDown
                size="xs"
                selected={
                  typeof state.tarif_ess_9_10cv.assiete === "string"
                    ? initAssietes.assiete_ess_9_10cv
                    : state.tarif_ess_9_10cv.assiete
                }
                options={ASSIETTES_TARIF}
                setSelected={(value) =>
                  setState({
                    ...state,
                    tarif_ess_9_10cv: {
                      ...state.tarif_ess_9_10cv,
                      assiete: value,
                    },
                  })
                }
                type="number"
              />
            </div>
          </div>
          <h5>{"PF > 10 CV"}</h5>
          <div className="tarifs-view">
            <div className="creation-element space-right">
              <h5>Tarif HT (%)</h5>
              <AppInput
                size="lg"
                value={state.tarif_ess_10cv.tarif_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_ess_10cv: {
                      ...state.tarif_ess_10cv,
                      tarif_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Prime HT minimun </h5>
              <AppInput
                size="lg"
                value={state.tarif_ess_10cv.prim_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_ess_10cv: {
                      ...state.tarif_ess_10cv,
                      prim_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Assiette d'application du % de tarif</h5>
              <AppDropDown
                size="xs"
                selected={
                  typeof state.tarif_ess_10cv.assiete === "string"
                    ? initAssietes.assiete_ess_10cv
                    : state.tarif_ess_10cv.assiete
                }
                options={ASSIETTES_TARIF}
                setSelected={(value) =>
                  setState({
                    ...state,
                    tarif_ess_10cv: {
                      ...state.tarif_ess_10cv,
                      assiete: value,
                    },
                  })
                }
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="dc-tarifs-container">
        <h5> Gazoil </h5>
        <div className="creation-element">
          <h5>{"PF <= 4 CV"}</h5>
          <div className="tarifs-view">
            <div className="creation-element space-right">
              <h5>Tarif HT (%)</h5>
              <AppInput
                size="lg"
                value={state.tarif_gas_4cv.tarif_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_gas_4cv: {
                      ...state.tarif_gas_4cv,
                      tarif_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Prime HT minimun </h5>
              <AppInput
                size="lg"
                value={state.tarif_gas_4cv.prim_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_gas_4cv: {
                      ...state.tarif_gas_4cv,
                      prim_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Assiette d'application du % de tarif</h5>
              <AppDropDown
                size="xs"
                selected={
                  typeof state.tarif_gas_4cv.assiete === "string"
                    ? initAssietes.assiete_gas_4cv
                    : state.tarif_gas_4cv.assiete
                }
                options={ASSIETTES_TARIF}
                setSelected={(value) =>
                  setState({
                    ...state,
                    tarif_gas_4cv: {
                      ...state.tarif_gas_4cv,
                      assiete: value,
                    },
                  })
                }
                type="number"
              />
            </div>
          </div>
          <h5>{"PF = 5 CV"}</h5>
          <div className="tarifs-view">
            <div className="creation-element space-right">
              <h5>Tarif HT (%)</h5>
              <AppInput
                size="lg"
                value={state.tarif_gas_5cv.tarif_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_gas_5cv: {
                      ...state.tarif_gas_5cv,
                      tarif_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Prime HT minimun </h5>
              <AppInput
                size="lg"
                value={state.tarif_gas_5cv.prim_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_gas_5cv: {
                      ...state.tarif_gas_5cv,
                      prim_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Assiette d'application du % de tarif</h5>
              <AppDropDown
                size="xs"
                selected={
                  typeof state.tarif_gas_5cv.assiete === "string"
                    ? initAssietes.assiete_gas_5cv
                    : state.tarif_gas_5cv.assiete
                }
                options={ASSIETTES_TARIF}
                setSelected={(value) =>
                  setState({
                    ...state,
                    tarif_gas_5cv: {
                      ...state.tarif_gas_5cv,
                      assiete: value,
                    },
                  })
                }
              />
            </div>
          </div>
          <h5>{"PF = 6 ou 7 CV"}</h5>
          <div className="tarifs-view">
            <div className="creation-element space-right">
              <h5>Tarif HT (%)</h5>
              <AppInput
                size="lg"
                value={state.tarif_gas_6_7cv.tarif_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_gas_6_7cv: {
                      ...state.tarif_gas_6_7cv,
                      tarif_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Prime HT minimun </h5>
              <AppInput
                size="lg"
                value={state.tarif_gas_6_7cv.prim_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_gas_6_7cv: {
                      ...state.tarif_gas_6_7cv,
                      prim_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Assiette d'application du % de tarif</h5>
              <AppDropDown
                size="xs"
                selected={
                  typeof state.tarif_gas_6_7cv.assiete === "string"
                    ? initAssietes.assiete_gas_6_7cv
                    : state.tarif_gas_6_7cv.assiete
                }
                options={ASSIETTES_TARIF}
                setSelected={(value) =>
                  setState({
                    ...state,
                    tarif_gas_6_7cv: {
                      ...state.tarif_gas_6_7cv,
                      assiete: value,
                    },
                  })
                }
              />
            </div>
          </div>
          <h5>{"PF >= 8 CV"}</h5>
          <div className="tarifs-view">
            <div className="creation-element space-right">
              <h5>Tarif HT (%)</h5>
              <AppInput
                size="lg"
                value={state.tarif_gas_8cv.tarif_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_gas_8cv: {
                      ...state.tarif_gas_8cv,
                      tarif_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Prime HT minimun </h5>
              <AppInput
                size="lg"
                value={state.tarif_gas_8cv.prim_ht}
                onChange={(t) =>
                  setState({
                    ...state,
                    tarif_gas_8cv: {
                      ...state.tarif_gas_8cv,
                      prim_ht: t ? parseFloat(t) : "",
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="creation-element space-right">
              <h5>Assiette d'application du % de tarif</h5>
              <AppDropDown
                size="xs"
                selected={
                  typeof state.tarif_gas_8cv.assiete === "string"
                    ? initAssietes.assiete_gas_8cv
                    : state.tarif_gas_8cv.assiete
                }
                options={ASSIETTES_TARIF}
                setSelected={(value) =>
                  setState({
                    ...state,
                    tarif_gas_8cv: {
                      ...state.tarif_gas_8cv,
                      assiete: value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="section-form-view">
      <div className="creation-element">
        <h5>Plafond</h5>
        <AppInput
          size="lg"
          value={state.plafond}
          onChange={(t) =>
            setState({ ...state, plafond: t ? parseFloat(t) : "" })
          }
          type="number"
        />
      </div>
      <div className="creation-element">
        <h5>Description du plafond de la garantie</h5>
        <AppInput
          size="lg"
          value={state.desc_plafond_content}
          onChange={(t) => setState({ ...state, desc_plafond_content: t })}
        />
      </div>
      <div className="creation-element">
        <h5>Description de la Franchise</h5>
        <AppInput
          size="lg"
          value={state.desc_franchise}
          onChange={(t) => setState({ ...state, desc_franchise: t })}
        />
      </div>
      {props.withTarif && renderTarifs()}
    </div>
  );
};

export default FormuleForm;
