import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";

import { listStateSelect, currentUserStateSelect } from "../../selector";

import RequestsList from "./component";

const RequestsListContainer = (props) => {
  const currentUser = useSelector(currentUserStateSelect);
  const list = useSelector(listStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <RequestsList
      list={list}
      currentUser={currentUser}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default RequestsListContainer;
