import React from "react";
import { /* useSelector, */ useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "./actions";
// import { authStateSelect } from "./selector";

import Homeview from "./component";

const HomeviewContainer = (props) => {
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return <Homeview actions={actionsDispatch} {...props} />;
};

export default HomeviewContainer;
