import React from "react";
// import ApiConfig from "config/apiConfig";

export const columns = [
  {
    title: "Nom",
    dataIndex: "name.last",
    renderItem: (info, props) => <span> {info.name.first} </span>,
  },
  {
    title: "Prénom",
    dataIndex: "name.first",
    renderItem: (info, props) => <span>{info.name.last}</span>,
  },
  {
    title: "Email",
    dataIndex: "email",
    renderItem: (info, props) => <span>{info.email}</span>,
  },
  {
    title: "Téléphone",
    dataIndex: "phone",
    renderItem: (info, props) => <span>{info.phone}</span>,
  },
  {
    title: "Employeur",
    dataIndex: "employer.imageUrl",
    renderItem: (info, props) => (
      <div className="text-center">
        <img
          src={`${
            process.env.REACT_APP_DOMAIN
          }${info.employer?.imageUrl.replace("size=300x300", "")}`}
          className="societe-logo"
          alt="."
        />
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "none",
    renderItem: () => {
      return (
        <div className="text-right">
          <img
            src={require("assets/img/chevron-right.png")}
            className="chevron-right"
            alt=">"
          />
        </div>
      );
    },
  },
];
