import React from "react";

import StatusTag from "components/StatusTag";
import PageDescription from "components/PageDescription";

// some hooks
import moment from "lib/moment";
import capitalize from "lib/capitalize";
import { useCheckAuth } from "hooks/useCheckAuth";
import useFetchElement from "hooks/useFetchElement";

import Report from "../report";

// endpoints
import { requestByIdUrl } from "../../api/endpoints";

import "../../requests-styles.scss";
import "assets/css/elementDetails.css";

const statusText = {
  IN_PROGRESS: "Devis en attente de confirmation",
  NOT_CONFIRMED: "Devis en attente de confirmation",
  CONFIRMED_WITHOUT_PJ: "Devis confirmé, manque PJ",
  CONFIRMED_WITH_PJ: "Confirmé",
};

const filesNames = {
  cgRecto: "Carte grise Recto",
  cgVerso: "Carte grise Verso",
  cnieRecto: "CNIE Recto",
  cnieVerso: "CNIE Verso",
  permisRecto: "Permis de conduire Recto",
  permisVerso: "Permis de conduire Verso",
  cessionSalaire: "Cession de délégation sur salaire",
  attTravail: "Attestation de travail ou Badge",
  bullSoucription: "Bulletin de souscription",
};

export default function RequestDetails(props) {
  useCheckAuth();
  const { current } = props.details;
  const { data: request /*, fetching */ } = useFetchElement(
    current,
    requestByIdUrl(current.id)
    // () => props.actions.setCurrentDemand(request),
  );

  return (
    <div className="app-main">
      <div className="app-content ">
        <h3 className="detail-title-text">{`Demandes > ${capitalize(
          current?.userData?.name.first
        )} ${capitalize(current?.userData?.name.last)}`}</h3>
        <div className="req-info-view">
          <h5>Date de la demande:</h5>
          <span className="req-info-value">
            {moment(current?.created_at).format("lll")}
          </span>
        </div>
        <div className="req-info-view">
          <h5>Statut de la demande:</h5>
          <div className="space-left status-employer">
            <StatusTag
              label={statusText[current?.statut]}
              status={`tag-${current?.statut?.toLowerCase()}`}
            />
          </div>
        </div>
        <div className="req-info-view">
          <h5>Convention:</h5>
          <span className="req-info-value">
            {current?.conventionData?.name}
          </span>
        </div>
        <div>
          <div className="row-info">
            <div className="req-info-view">
              <h5>Type:</h5>
              <span className="req-info-value">
                {current?.type === "pack" ? "Pack" : "à la Carte"}
              </span>
            </div>
            {current?.type === "pack" && (
              <div className="req-info-view">
                <h5>Nom du pack:</h5>
                <span className="req-info-value">
                  {current?.packData?.name}
                </span>
              </div>
            )}
          </div>
          <div className="row-info">
            <div className="req-info-view">
              <h5>Assuré:</h5>
              <span className="req-info-value">
                {`${capitalize(
                  current?.interestedData?.name.first
                )} ${capitalize(current?.interestedData?.name.last)}`}
              </span>
            </div>
            <div className="req-info-view">
              <h5>Employeur:</h5>
              <span className="req-info-value">
                {current?.employerData?.name}
              </span>
            </div>
          </div>
          <div className="row-info">
            <div className="req-info-view">
              <h5>Matricule:</h5>
              <span className="req-info-value">
                {current?.vehiculeData?.immatriculation}
              </span>
            </div>
            <div className="req-info-view">
              <h5>Motorisation/PF:</h5>
              <span className="req-info-value">
                {`${current?.vehiculeData.motorisation}/${current?.vehiculeData.pf}`}
              </span>
            </div>
          </div>
        </div>
        <div className="req-info-view">
          <h5>Mode de paiement:</h5>
          <span className="req-info-value">{current?.paymentMode}</span>
        </div>
        {current?.filesUrl &&
          Object.keys(current?.filesUrl).map((file) => (
            <div className="req-info-view">
              <h5>{filesNames[file]}:</h5>
              <span
                tab="button"
                onClick={() => {}}
                className="req-info-value download-l"
              >
                Télécharger
              </span>
            </div>
          ))}

        <Report statut={current?.statut} />
      </div>
    </div>
  );
}
