// declare actions types
import {
  SET_CREATED_CONV,
  SET_CURRENT_CONV,
  UPDATE_CREATION_FORM,
  CLEAR_CREATION_FORM,
} from "../actionsTypes";

import { LOGOUT_SUCCESS } from "actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  current: null,
  editing: false,
  packs: [],
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function create(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CONV:
      return {
        ...state,
        current: action.payload,
        editing: true,
      };

    case SET_CREATED_CONV:
      return { ...state, current: action.payload };

    case UPDATE_CREATION_FORM:
      return { ...state, ...action.payload };

    case CLEAR_CREATION_FORM:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
