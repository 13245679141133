import React, { useState, useEffect } from "react";

import { GARANTIES, CONTRAINTE } from "config/app";

import { Alert, Spinner } from "reactstrap";

import ButtonTag from "components/ButtonTag";
import Section from "./section";
import ConstraintesForm from "./forms/constraintsForms";

import { useFetchOne } from "../../hooks/useFetchGaranties";
import { useUpdateGarantie } from "../../hooks/useUpdateGarantie";

import "../../scss/garanties-styles.scss";

const readonlyCodes = ["g1", "g2"];

const Garanties = (props) => {
  const [
    { fetching: fetchingUpdate, error },
    updateGarantie,
  ] = useUpdateGarantie();
  const [activeSection, setActive] = useState(null);
  const [constraints, setConstraints] = useState([]);
  const { current } = props.create;
  const { list, expanded } = props.garanties;
  const [fetching, fetchData] = useFetchOne();

  const [displayedGaranties, setGaranties] = useState([]);

  useEffect(() => {
    const newList = GARANTIES.map((one) => {
      return {
        ...one,
        _id: list[one.code]?._id,
        isEnabled: list[one.code]?.isEnabled,
      };
    });
    setGaranties([...newList]);
  }, [list]);

  useEffect(() => {
    setConstraints(list.contraintes || []);
  }, [list.contraintes]);

  const addNewConstraint = () => {
    setConstraints(
      constraints.concat([
        {
          ...CONTRAINTE,
          num: Math.random(),
          pack: props.pack,
        },
      ])
    );
  };

  const onRemoveConstraint = (i, id) => {
    constraints.splice(i, 1);
    setConstraints([...constraints]);
    if (id) {
      props.actions.removeContrainte(id);
    }
  };

  const updatePack = () => {
    updateGarantie(
      {
        contraintes: constraints,
      },
      "pack"
    );
  };

  const renderConstraints = () => {
    return (
      <div className="constraints-view">
        <span>Contraintes</span>
        {constraints.map((c, i) => (
          <div key={c._id || c.num.toString()} className="formule-view-item">
            <div style={{ flex: 1 }}>
              <ConstraintesForm
                key={c._id || c.num.toString()}
                contrainte={c}
                selected={constraints}
                onFormChange={(form) => {
                  const old = constraints;
                  old.splice(i, 1, form);
                  setConstraints(old);
                }}
              />
            </div>
            <div
              onClick={() => onRemoveConstraint(i, c._id)}
              className="deps-rm"
            >
              <img
                alt="x"
                src={require("assets/img/rm-icon.png")}
                className="rm-icon"
              />
            </div>
          </div>
        ))}
        <div className="button-tag-grp">
          <ButtonTag
            onClick={addNewConstraint}
            label="Ajouter une contrainte"
            active
          />
          {constraints.length > 0 && (
            <div className="space-left">
              <ButtonTag
                onClick={updatePack}
                label={
                  fetchingUpdate ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    "Sauvegarder"
                  )
                }
                active
                primary
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {displayedGaranties.map((garantie, i) => (
        <Section
          key={garantie.code}
          activeSection={activeSection}
          enabled={garantie.isEnabled}
          garantie={garantie}
          carte={current.carte}
          onExpand={() => {
            if (activeSection === garantie.code && expanded) {
              setActive(null);
              props.actions.setExpandedGarantie(null);
            } else {
              setActive(garantie.code);
              props.actions.setExpandedGarantie({});
              fetchData(garantie._id);
            }
          }}
          expandedValue={expanded}
          expanded={expanded !== null && activeSection === garantie.code}
          actions={props.actions}
          readonly={readonlyCodes.includes(garantie.code)}
          fetching={fetching}
        />
      ))}
      {renderConstraints()}
      {error ? (
        <Alert color="danger">
          {error.message || "Un erreur est survenue. Veuillez réessayer"}
        </Alert>
      ) : null}
    </>
  );
};

export default Garanties;
