import React, { useState, useEffect } from "react";

import { useCreate } from "modules/Conventions/hooks/useCreate";

import { Alert } from "reactstrap";
import AppInput from "components/AppInput";
import ButtonTag from "components/ButtonTag";
import PackView from "../packView";
import StepFooter from "./stepFooter";

const Step1 = (props) => {
  const [values, createConvention] = useCreate();
  const { current } = props.create;
  const [withPTA, setWithPTA] = useState(current?.link_frais_accessoires);
  const [state, setState] = useState({
    conventionName: current?.name || "",
    conventionNumber: current?.number || "",
    isCard: current?.hasCarte || false,
    isPack: current?.hasPacks || false,
    acceptCash: current?.paymentModes.includes("cash") || false,
    acceptPrelevement: current?.paymentModes.includes("prelevement") || false,
    frais_accessoires: current?.frais_accessoires,
    pta_gratuit: current?.pta_gratuit,
    pta_non_gratuit: current?.pta_non_gratuit,
    crm: current?.crm || 1,
    packs: current?.packs || [],
    editingPack: null,
  });

  useEffect(() => {
    if (values.data) {
      props.history.push("/conventions/create#paramaters");
    }
  }, [props.actions, props.history, values.data]);

  const addNewPack = () => {
    const newPack = {
      num: state.packs.length + 1,
      name: "",
      imageUrl: null,
    };
    setState({
      ...state,
      editingPack: newPack,
      packs: state.packs.concat([newPack]),
    });
  };

  const onRemovePack = (i, pack) => {
    const packs = state.packs;
    packs.splice(i, 1);
    setState({ ...state, packs: [...packs] });
  };

  const editHasCarte = () => {
    setState({ ...state, isCard: !state.isCard });
  };

  const editHasPacks = () => {
    setState({ ...state, isPack: !state.isPack });
  };

  const onSubmit = () => {
    let paymentModes = [];
    if (state.acceptCash) {
      paymentModes.push("cash");
    }
    if (state.acceptPrelevement) {
      paymentModes.push("prelevement");
    }
    const payload = {
      name: state.conventionName,
      number: parseInt(state.conventionNumber, 10),
      hasPacks: state.isPack,
      hasCarte: state.isCard,
      crm: parseInt(state.crm, 10),
      paymentModes,
      link_frais_accessoires: withPTA,
      ...(!withPTA && {
        frais_accessoires: parseInt(state.frais_accessoires, 10),
      }),
      ...(withPTA && { pta_gratuit: parseInt(state.pta_gratuit, 10) }),
      ...(withPTA && { pta_non_gratuit: parseInt(state.pta_non_gratuit, 10) }),
      packs: !state.isPack ? [] : state.packs, //if not haspacks send empty list
    };

    props.actions.updateCreationForm({ packs: state.packs });

    createConvention(payload, current?._id);

    // the suppression of created packs and carte will be done in BE
  };

  const invalidForm =
    !state.conventionName ||
    (state.isPack && !state.packs.length) ||
    !state.crm ||
    (!state.acceptCash && !state.acceptPrelevement) ||
    (withPTA && !state.pta_gratuit && !state.pta_non_gratuit) ||
    (!withPTA && !state.frais_accessoires);

  const renderPacks = () => (
    <div className="creation-element">
      <h5>Packs</h5>
      {state.packs.map((pack, i) => (
        <PackView
          key={pack?.num}
          image={pack?.imageUrl}
          name={pack?.name}
          onRemove={() => onRemovePack(i, pack)}
          updateImage={(img) => {
            console.log("upadte imgs", img);
            const packs = state.packs;
            const newPack = {
              ...packs[i],
              imageUrl: img,
            };
            packs.splice(i, 1, newPack);
            setState({
              ...state,
              packs: [...packs],
            });
          }}
          updateName={(name) => {
            const packs = state.packs;
            const newPack = {
              ...packs[i],
              name,
            };
            packs.splice(i, 1, newPack);
            setState({
              ...state,
              packs: [...packs],
            });
          }}
        />
      ))}
      <ButtonTag onClick={addNewPack} label="Ajouter un pack" active />
    </div>
  );

  return (
    <>
      <div className="creation-element">
        <h5>Nom de la convention</h5>
        <AppInput
          size="lg"
          value={state.conventionName}
          onChange={(t) => setState({ ...state, conventionName: t })}
        />
      </div>
      <div className="creation-element">
        <h5>
          Numéro de la convention
          <span className="text-optionnel">(Optionnel)</span>
        </h5>
        <AppInput
          size="lg"
          value={state.conventionNumber}
          onChange={(t) => setState({ ...state, conventionNumber: t })}
        />
      </div>
      <div className="creation-element">
        <h5>Type de la convention</h5>
        <div className="convs-types">
          <ButtonTag
            onClick={editHasCarte}
            label="À la carte"
            active={state.isCard}
          />
          <ButtonTag
            onClick={editHasPacks}
            label="Packs"
            active={state.isPack}
          />
        </div>
      </div>
      <div className="creation-element">
        <h5>Mode de paiement</h5>
        <div className="convs-types">
          <ButtonTag
            onClick={() =>
              setState({ ...state, acceptCash: !state.acceptCash })
            }
            label="Comptant"
            active={state.acceptCash}
          />
          <ButtonTag
            onClick={() =>
              setState({
                ...state,
                acceptPrelevement: !state.acceptPrelevement,
              })
            }
            label="Prélèvement sur salaire"
            active={state.acceptPrelevement}
          />
        </div>
      </div>
      <div className="creation-element">
        <h5>Frais des accessoires</h5>
        <div className="step-chk-btn" onClick={() => setWithPTA(!withPTA)}>
          <span className="step-field">
            <img
              alt="v"
              src={
                withPTA
                  ? require("assets/img/checked.png")
                  : require("assets/img/noChecked.png")
              }
              className="step-checkmark"
            />
            Lier les frais d’accessoires à la PTA
          </span>
        </div>
        <div className="convs-types space-between">
          {!withPTA && (
            <AppInput
              size="sm"
              value={state.frais_accessoires}
              onChange={(t) => setState({ ...state, frais_accessoires: t })}
              placeholder="Frais d'accessoires"
            />
          )}
          {withPTA && (
            <>
              <div>
                <AppInput
                  size="sm"
                  value={state.pta_gratuit}
                  onChange={(t) => setState({ ...state, pta_gratuit: t })}
                  placeholder="PTA gratuit"
                />
              </div>
              <div className="space-left">
                <AppInput
                  size="sm"
                  value={state.pta_non_gratuit}
                  onChange={(t) => setState({ ...state, pta_non_gratuit: t })}
                  placeholder="PTA non gratuit"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="creation-element">
        <h5>CRM</h5>
        <AppInput
          size="sm"
          value={state.crm}
          onChange={(t) => setState({ ...state, crm: t })}
        />
      </div>
      {state.isPack ? renderPacks() : null}
      <StepFooter
        previous={false}
        onPressNext={() => onSubmit()}
        next="/conventions/create#paramaters"
        nextDisabled={invalidForm}
        fetching={values.fetching}
        history={props.history}
        onCancel={props.onCancel}
      />
      {values.error ? (
        <Alert color="danger">
          {values.error.message || "Un erreur est survenue. Veuillez réessayer"}
        </Alert>
      ) : null}
    </>
  );
};

export default Step1;
