import React, { useState } from "react";
import classNames from "classnames";

const Tabsview = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="tabs-view-container">
      {props.tabs.map((tab, i) => (
        <div
          key={tab._id}
          className={classNames("tab-view", {
            active: activeTab === i,
          })}
          onClick={() => {
            setActiveTab(i);
            props.onChange(i);
          }}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );
};

export default Tabsview;
