import React, { useEffect, useState } from "react";

// some config variables
import { steps } from "./creationConfig";

import PageDescription from "components/PageDescription";
import ConfirmModal from "components/ConfirmModal";
import CreationStepper from "./creationStepper";

import "../../scss/creation-styles.scss";

export default function ConventionsCreate(props) {
  const { location } = props;
  const { editing } = props.create;
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    const step = steps.find((one) => one.hash === location.hash);
    setActiveStep(step || steps[0]);
  }, [location.hash]);

  const onCancelProcess = () => {
    setDisplayModal(true);
  };

  return (
    <div className="app-main">
      <PageDescription
        icon={require("assets/img/heart.png")}
        title="Nouvelle convention"
      />
      <div className="stepper-view">
        <CreationStepper activeStep={activeStep.index} />
      </div>
      <div>
        <activeStep.component {...props} onCancel={onCancelProcess} />
      </div>
      {displayModal && (
        <ConfirmModal
          isOpen={displayModal}
          onConfirm={() => {
            props.actions.clearCreationForm();
            if (!editing) {
              props.actions.deleteConvention(props.create.current?._id);
            }
            props.history.push("/conventions");
          }}
          onCloseModal={() => setDisplayModal(false)}
        />
      )}
    </div>
  );
}
