import React, { useState, useEffect } from "react";

import { GARANTIES } from "config/app";
import Section from "./section";

import { useFetchOne } from "../../hooks/useFetchGaranties";

import "../../scss/garanties-styles.scss";

const readonlyCodes = ["g1", "g2"];

const Garanties = (props) => {
  const [activeSection, setActive] = useState(null);
  const { current } = props.create;
  const { list, expanded } = props.garanties;
  const [fetching, fetchData] = useFetchOne();

  const [displayedGaranties, setGaranties] = useState([]);

  useEffect(() => {
    const newList = GARANTIES.map((one) => {
      return {
        ...one,
        _id: list[one.code]?._id,
        isEnabled: list[one.code]?.isEnabled,
      };
    });
    setGaranties([...newList]);
  }, [list]);

  return (
    <>
      {displayedGaranties.map((garantie, i) => (
        <Section
          key={garantie.code}
          activeSection={activeSection}
          enabled={garantie.isEnabled}
          garantie={garantie}
          carte={current.carte}
          onExpand={() => {
            if (activeSection === garantie.code && expanded) {
              setActive(null);
              props.actions.setExpandedGarantie(null);
            } else {
              props.actions.setExpandedGarantie({});
              setActive(garantie.code);
              fetchData(garantie._id);
            }
          }}
          expandedValue={expanded}
          expanded={expanded !== null && activeSection === garantie.code}
          actions={props.actions}
          readonly={readonlyCodes.includes(garantie.code)}
          fetching={fetching}
        />
      ))}
    </>
  );
};

export default Garanties;
