// declare actions types
import {
  SET_CONV_GARANTIES,
  SET_EXPANDED_GARANTIE,
  UPDATE_EXPANDED_GARANTIE,
  CLEAR_CREATION_FORM,
  UPDATE_CURRENT_PACK,
} from "../actionsTypes";

import { LOGOUT_SUCCESS } from "actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  expanded: null,
  list: {},
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function create(state = initialState, action) {
  switch (action.type) {
    case SET_EXPANDED_GARANTIE:
      return { ...state, expanded: action.payload };

    case UPDATE_CURRENT_PACK:
      return { ...state, list: { ...state.list, ...action.payload } };

    case UPDATE_EXPANDED_GARANTIE:
      return { ...state, expanded: { ...state.expanded, ...action.payload } };

    case SET_CONV_GARANTIES:
      return { ...state, list: action.payload };

    case CLEAR_CREATION_FORM:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
