import React from "react";

import DcFORM from "./dcForm";
import OthersFORM from "./othersForm";

const FormuleForm = (props) => {
  switch (props.code) {
    case "g6":
      return <DcFORM {...props} />;
    default:
      return <OthersFORM {...props} />;
  }
};

export default FormuleForm;
