import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import { detailsStateSelect } from "../../selector";

import DocsDetailsView from "./component";

const DocsDetailsViewContainer = (props) => {
  const details = useSelector(detailsStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <DocsDetailsView details={details} actions={actionsDispatch} {...props} />
  );
};

export default DocsDetailsViewContainer;
