import { createSelector } from "reselect";

export const listStateSelect = createSelector(
  (state) => state.clients,
  (clients) => clients.list
);

export const detailsStateSelect = createSelector(
  (state) => state.clients,
  (clients) => clients.details
);
