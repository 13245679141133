import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";

const RequestForm = (props) => {
  const onBack = () => {
    props.actions.resetForgot();
    props.history.replace("/auth/signin");
  };

  return (
    <Container className="forgot-view">
      <Row>
        <Col className="login-content" sm={{ size: 6, offset: 3 }}>
          <div className="auth-logo-container">
            <img
              className="login-logo"
              alt="..."
              src={require("assets/img/login_logo.png")}
            ></img>
          </div>
          <div>
            <h4 className="text-left login-text-bold  forgot-text-regular">
              Parfait !{" "}
              <span>
                <img
                  className="up-icon"
                  src={require("assets/img/up.png")}
                  alt="up"
                />{" "}
              </span>
            </h4>
            <h4 className="text-left forgot-text-regular">
              {`Nous vous avons transmis un message sur l'adresse ${props.auth.userEmail}
               pour réinitialiser vos accès`}
              <br />
            </h4>
            <div
              className="text-left forgot-bottom-view"
              tag="button"
              onClick={onBack}
            >
              <h5 className="forgot-text-back">
                Revenir à l'écran authentification{" "}
                <span>
                  {" "}
                  <img
                    className="back-icon"
                    src={require("assets/img/arrow-right-white.png")}
                    alt="back"
                  />{" "}
                </span>{" "}
              </h5>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RequestForm;
