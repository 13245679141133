import React, { useState, useEffect } from "react";

import { DEPENDENCIES, GARANTIES } from "config/app";

import AppDropDown from "components/AppDropDown";

const DependenciesForm = ({ onFormChange, ...props }) => {
  const [initValues, setInitValues] = useState({
    type: props.readonly ? DEPENDENCIES[0] : null,
    target: null,
  });
  const [garanties, setGaranties] = useState([]);
  const [state, setState] = useState(props.dependance);

  useEffect(() => {
    const list = GARANTIES.filter((one) => one.code !== props.code).map(
      (one) => ({
        label: `${one.code.replace("g", "")}. ${one.name}`,
        value: one.code,
      })
    );
    setGaranties(list);
  }, [props.code]);

  useEffect(() => {
    const c = DEPENDENCIES.find((one) => one.value === props.dependance.type);
    const t = GARANTIES.find((one) => one.code === props.dependance.target);
    if (c || t) {
      setInitValues({
        ...(c && { type: c }),
        ...(t && {
          target: {
            label: `${t.code.replace("g", "")}. ${t.name}`,
            value: t.code,
          },
        }),
      });
    }
  }, [props.dependance.target, props.dependance.type]);

  useEffect(() => {
    const t = state.type.value || state.type;
    onFormChange({
      ...state,
      type: t,
      target: t === "DEFAULT" ? props.code : state.target.value || state.target,
    });
  }, [onFormChange, props.code, state]);

  const renderGaranties = () => {
    const t = typeof state.type === "string" ? initValues.type : state.type;

    if (t && t.value !== "DEFAULT") {
      return (
        <div className="creation-element deps-element">
          <h5>Garantie associée</h5>
          <AppDropDown
            selected={
              typeof state.target === "string"
                ? initValues.target
                : state.target
            }
            options={garanties}
            setSelected={(value) => setState({ ...state, target: value })}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="section-deps-view">
      <div className="creation-element deps-element">
        <h5>Type de dépendance</h5>
        <AppDropDown
          selected={
            typeof state.type === "string" ? initValues.type : state.type
          }
          options={DEPENDENCIES}
          setSelected={(value) => setState({ ...state, type: value })}
        />
      </div>
      {renderGaranties()}
    </div>
  );
};

export default DependenciesForm;
