import React from "react";
// import ApiConfig from "config/apiConfig";

export const columns = [
  {
    title: "",
    dataIndex: "imageUrl",
    renderItem: (info, props) => (
      <div className="text-center">
        <img
          src={`${process.env.REACT_APP_DOMAIN}${info.imageUrl.replace(
            "size=300x300",
            ""
          )}`}
          className="societe-logo"
          alt="."
        />
      </div>
    ),
  },
  {
    title: "Nom",
    dataIndex: "name",
    renderItem: (info, props) => <span>{info.name}</span>,
  },
  {
    title: "Convention",
    dataIndex: "conventionName",
    renderItem: (info, props) => <span>{info.conventionName}</span>,
  },
  {
    title: "",
    dataIndex: "none",
    renderItem: () => {
      return (
        <div className="text-right">
          <img
            src={require("assets/img/chevron-right.png")}
            className="chevron-right"
            alt=">"
          />
        </div>
      );
    },
  },
];
