import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import { listStateSelect } from "../../selector";

import ConventionsListView from "./component";

const ConventionsListViewContainer = (props) => {
  const list = useSelector(listStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <ConventionsListView list={list} actions={actionsDispatch} {...props} />
  );
};

export default ConventionsListViewContainer;
