import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import routes from "./routes";
import { detailsStateSelect } from "./selector";

function HealthDocs({ match, location }) {
  const details = useSelector(detailsStateSelect);

  useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });

  return (
    <div>
      <Helmet>
        <title>AGMA-BO</title>
        <meta name="Conventions" content="Conventions" />
      </Helmet>
      <Switch>
        {routes.map((route, i) => {
          if (location.pathname === "/conventions") {
            return <Redirect key={route.path} to="/conventions/list" />;
          }
          if (
            location.pathname === "/conventions/details" &&
            !details.current
          ) {
            return <Redirect key={route.path} to="/conventions/list" />;
          }
          return (
            <Route
              key={route.path}
              path={`${match.path}${route.path}`}
              render={(props) => <route.component {...props} />}
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default HealthDocs;
