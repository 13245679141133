import React, { useEffect, useState } from "react";
import { omit } from "lodash";

import { Alert, Spinner } from "reactstrap";

import { ASSISTANCE } from "config/app";

import ButtonTag from "components/ButtonTag";
import StepFooter from "./stepFooter";
import AssistanceForm from "../assistanceForm";

import { useCreate } from "../../../hooks/useCreate";

const Step3 = (props) => {
  const { current } = props.create;
  const [assistances, setAssistances] = useState([]);
  const [{ fetching, error }, createConvention] = useCreate();

  const addNewAssistance = () => {
    setAssistances(assistances.concat([{ ...ASSISTANCE, num: Math.random() }]));
  };

  useEffect(() => {
    setAssistances(current?.assistances || current?.assistance || []);
  }, [current]);

  const onSave = () => {
    const payload = {
      ...(current && omit(current, ["_id", "id", "carte", "__v", "num"])),
      assistances,
    };
    createConvention(payload, current?._id);
  };

  return (
    <>
      <div className="conv-tab-content">
        {assistances.map((a, i) => (
          <div key={a._id || a.num.toString()} className="formule-view-item">
            <div
              onClick={() => {
                assistances.splice(i, 1);
                setAssistances([...assistances]);
              }}
              className="formule-rm"
            >
              <img
                alt="x"
                src={require("assets/img/rm-icon.png")}
                className="rm-icon"
              />
            </div>
            <div style={{ flex: 1 }}>
              <span className="formule-title">{`Formule ${i + 1}`}</span>
              <AssistanceForm
                key={a._id || a.num.toString()}
                formule={a}
                onFormChange={(form) => {
                  const old = assistances;
                  old.splice(i, 1, form);
                  setAssistances(old);
                }}
              />
            </div>
          </div>
        ))}
        <div className="button-tag-grp">
          <ButtonTag
            onClick={addNewAssistance}
            label="Ajouter une formule"
            active
          />
          <div className="space-left">
            <ButtonTag
              onClick={(e) => {
                onSave();
              }}
              label={
                fetching ? <Spinner size="sm" color="light" /> : "Sauvegarder"
              }
              active
              primary
            />
          </div>
        </div>
      </div>
      {error ? (
        <Alert color="danger">
          {error.message || "Un erreur est survenue. Veuillez réessayer"}
        </Alert>
      ) : null}
      <StepFooter
        previous
        next="/conventions/create#recap"
        // nextDisabled={invalidForm}
        history={props.history}
        onCancel={props.onCancel}
      />
    </>
  );
};

export default Step3;
