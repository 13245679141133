// routes components
import Login from "./containers/login";
import ForgotPassword from "./containers/forgotPassword";

const routes = [
  {
    path: "/signin",
    component: Login,
    exact: true,
  },
  {
    path: "/forgot",
    component: ForgotPassword,
    exact: true,
  },
];

export default routes;
