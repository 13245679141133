import React, { useState, useEffect } from "react";

import AppInput from "components/AppInput";

const FormuleForm = (props) => {
  const [state, setState] = useState(props.formule);
  const { onFormChange } = props;

  useEffect(() => {
    onFormChange({
      ...state,
      prix: state.prix ? state.prix : 0,
    });
  }, [onFormChange, state]);

  return (
    <div className="section-form-view creation-element">
      <div className="creation-element">
        <h5>Titre</h5>
        <AppInput
          size="lg"
          value={state.titre}
          onChange={(t) => setState({ ...state, titre: t })}
        />
      </div>
      <div className="creation-element">
        <h5>Description</h5>
        <AppInput
          size="lg"
          value={state.description}
          onChange={(t) => setState({ ...state, description: t })}
        />
      </div>
      <div className="creation-element">
        <h5>Prix</h5>
        <AppInput
          size="lg"
          value={state.prix}
          onChange={(t) =>
            setState({
              ...state,
              prix: t ? parseFloat(t) : "",
            })
          }
          type="number"
        />
      </div>
    </div>
  );
};

export default FormuleForm;
