import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";

// some plugins
import AsyncSelect from "react-select/async";

// some config variables

import PageDescription from "components/PageDescription";
import CustomTable from "components/CustomTable";
import EmptyList from "components/EmptyList";

import useFetchList from "hooks/useFetchList";

import { Api } from "api";
import { conventionsUrl } from "../../api/endpoints";
import * as tableConfig from "./tableConfig";

import "assets/css/listviews.css";

export default function ConventionsList(props) {
  const [fetchParams, setFecthParams] = useState({ skip: 0, top: 10 });
  const [apiEndpoint, setEndpoint] = useState(conventionsUrl(""));
  const { data, count, fetching } = useFetchList(apiEndpoint);

  useEffect(() => {
    const filter = fetchParams.employer
      ? JSON.stringify({ employer: fetchParams.employer })
      : "";
    const urlQuery = `$skip=${fetchParams.skip}&$top=${fetchParams.top}${
      fetchParams.q ? "&$q=" + fetchParams.q : ""
    }${filter ? "&$filter=" + filter : ""}`;
    setEndpoint(conventionsUrl(urlQuery));
  }, [fetchParams]);

  const loadOptions = (inputValue) =>
    new Promise(async (resolve) => {
      try {
        const filter = inputValue ? `$q=${inputValue}` : "";
        const data = await Api().get(`/employers/?${filter}`);
        const formatted = data.value.map((one) => ({
          value: one._id,
          label: one.name,
        }));
        resolve(formatted);
      } catch {
        resolve([]);
      }
    });

  const exportCsv = () => {};

  return (
    <div className="app-main">
      <PageDescription
        icon={require("assets/img/heart.png")}
        title="Liste des conventions"
      />
      <div className="list-top-view">
        <span className="text-bold export-btn" tag="button" onClick={exportCsv}>
          Exporter en CSV
        </span>
      </div>
      <div className="filters-view societe-list">
        <Button
          className="btn-round filter"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.actions.clearCreationForm();
            props.history.push("/conventions/create#general");
          }}
          size="lg"
        >
          Nouvelle convention
        </Button>
        <div style={{ display: "flex" }}>
          <Input
            placeholder="Chercher par nom"
            type="text"
            name="filter-name"
            className={"filter-input"}
            value={fetchParams.q}
            onChange={(e) => {
              e.preventDefault();
              setFecthParams({
                q: e.currentTarget.value,
                skip: 0,
                top: fetchParams.top,
              });
            }}
          ></Input>
          <AsyncSelect
            placeholder="Employeur"
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            className="react-select react-select-primary filter-input"
            classNamePrefix="react-select"
            onChange={(select) =>
              setFecthParams({ ...fetchParams, employer: select?.value })
            }
            isClearable
          />
        </div>
      </div>
      <div>
        {data.length === 0 ? (
          <EmptyList fetching={fetching} text={"Aucune convention trouvée"} />
        ) : (
          <CustomTable
            columns={tableConfig.columns}
            dataSource={data}
            cellKey={"id"}
            onRowPress={(item) => {
              props.actions.setCurrentConv(item);
              props.history.push("/conventions/create");
            }}
            pagination={{
              count,
              rowsPerPage: fetchParams.top,
              page: Math.round(fetchParams.skip / fetchParams.top),
            }}
            handleChangePage={(params) => {
              setFecthParams(params);
            }}
            handleChangeRowsPerPage={(params) => {
              setFecthParams(params);
            }}
            rowClickable
          />
        )}
      </div>
    </div>
  );
}
